//
import React, { useContext, useState, useEffect } from 'react'
import Dropdown from '../Dropdown'
import { FormSpy, useForm, useField, useFormState } from 'react-final-form'
import { loadStates } from 'reducers/modules/app'
import CreateNewContext from './createNew'
import store from 'reducers/store'
import isEmpty from 'lodash/isEmpty'
import DropdownWithSearch from 'components/ui/DropdownWithSearch'
import ModalMultiselect from 'components/ui/ModalMultiselect.jsx'
import RadioInput from 'components/ui/RadioInput'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { selectDropdownDefaultValue } from 'components/ui/form/defaultValues'
import EntityEditorContext from 'components/dashboard/entityEditorContext'
import MultiStepFormContext from './MultiStepFormContext'
import styles from './renderers.module.css'

export const required = (value) => (value ? undefined : 'Required')

export const prependEmptyOption = (options) => {
    if (!options) {
        options = []
    }

    return [
        {
            title: '',
            value: '',
        },
        ...options,
    ]
}

export const FormGroup = (options) => {
    const {
        error,
        label,
        infoLink,
        labelTag,
        hint,
        hintPosition,
        extraHint,
        meta = {},
        children,
        labelClassName,
        rootClassName = 'form-group',
        className,
        fullWidth,
        oneLine,
        inputGroup,
        widgetWrapClass,
        required,
        name,
        removeFieldDiv,
        hideTitleAndHint,
        prefix,
    } = options

    let extraClasses = ''
    let labelClasses = 'control-label select optional'
    if (labelClassName) labelClasses = `${labelClasses} ${labelClassName}`
    let fieldDivClasses = ''

    if (oneLine) {
        extraClasses = ''
    }

    if (inputGroup) {
        fieldDivClasses += 'input-group'
    }

    if (prefix) {
        fieldDivClasses += 'control-with-prefix'
    }

    if (widgetWrapClass) {
        fieldDivClasses += ' ' + widgetWrapClass
    }

    if (fullWidth) {
        extraClasses = ''
    }

    if (className) {
        extraClasses += ' ' + className
    }

    if (required) {
        extraClasses += ' required'
    }

    const hasError = ((meta.error || meta.submitError) && meta.touched) || error
    const inputError = meta.error || error || meta.submitError

    const errorMessage =
        Array.isArray(inputError) && inputError.length > 1
            ? inputError.join('. ')
            : inputError

    const body = (
        <>
            {' '}
            {hintPosition === 'top' && hint ? (
                <p
                    className="hint hint-top"
                    dangerouslySetInnerHTML={{ __html: hint }}
                />
            ) : null}
            {children}
            {hasError && (
                <span className="help-block">
                    <i className="fa fa-exclamation-circle" /> {errorMessage}
                </span>
            )}
            {hint && !hideTitleAndHint && hintPosition !== 'top' ? (
                <p
                    className="help-block"
                    dangerouslySetInnerHTML={{ __html: hint }}
                />
            ) : null}
            {extraHint && (
                <p
                    className="help-block warn-txt"
                    dangerouslySetInnerHTML={{ __html: extraHint }}
                />
            )}
        </>
    )

    return (
        <div
            className={
                rootClassName + extraClasses + (hasError ? ' has-error' : '')
            }
        >
            {labelTag ? (
                labelTag
            ) : label && !hideTitleAndHint ? (
                <>
                    <label
                        className={labelClasses}
                        onClick={focusOnControl}
                        htmlFor={
                            name ? fieldId(name) : label ? label : undefined
                        }
                    >
                        {label}
                    </label>

                    {infoLink && (
                        <a
                            className="control-label--info-link"
                            href={infoLink}
                            target="_blank"
                        >
                            <i class="fas fa-info-circle" />
                        </a>
                    )}
                </>
            ) : null}

            {removeFieldDiv ? (
                body
            ) : (
                <div className={fieldDivClasses.trim()}>
                    {prefix ? (
                        <span className="input-prefix">{prefix}</span>
                    ) : undefined}
                    {body}
                </div>
            )}
        </div>
    )
}

export const UnitAppender = ({ unit, children, pullLeft = false }) => {
    const { distance_unit, volume_unit, weight_unit } = useSelector(
        ({
            app: {
                user_settings: {
                    distance_unit = 'KM',
                    volume_unit = 'm',
                    weight_unit = 'kg',
                } = {},
            } = {},
        }) => ({
            distance_unit,
            volume_unit,
            weight_unit,
        })
    )

    if (unit) {
        switch (unit) {
            case 'volume':
                unit = volume_unit
                break
            case 'weight':
                unit = weight_unit
                break
            case 'distance':
                unit = distance_unit
                break
        }

        const unitSpan = <span className="input-group-addon">{unit}</span>

        return (
            <div className="input-group">
                {pullLeft && unitSpan}
                {children}
                {!pullLeft && unitSpan}
            </div>
        )
    }

    return children
}

export const fieldInput = (options) => {
    const {
        error,
        title,
        infoLink,
        input,
        meta,
        hint,
        unit,
        disabled,
        compact,
        fullWidth,
        className,
        oneLine,
        input: { type },
        readOnly,
        required,
        leftIcon,
        rightLabel,
        hideTitleAndHint,
        prefix,
    } = options

    const multiFormContext = useContext(MultiStepFormContext)
    const extraProps = {}
    if (multiFormContext && multiFormContext.disableAutocomplete) {
        extraProps.autoComplete = 'new-password'
    }

    return (
        <FormGroup
            error={error}
            meta={meta}
            label={title}
            infoLink={infoLink}
            name={input.name}
            hint={hint}
            compact={compact}
            fullWidth={fullWidth}
            className={className}
            oneLine={oneLine}
            required={required}
            hideTitleAndHint={hideTitleAndHint}
            prefix={prefix}
        >
            <UnitAppender unit={unit}>
                <div className={leftIcon ? 'input-group' : ''}>
                    {leftIcon && (
                        <span className="input-group-addon">
                            <span className={'fa fa-' + leftIcon} />
                        </span>
                    )}
                    <input
                        className="form-control string optional"
                        readOnly={readOnly || disabled}
                        placeholder={options.placeholder}
                        {...processInputProps(input)}
                        id={fieldId(input.name)}
                        type={type ? type : 'text'}
                        {...(required ? { 'aria-required': 'true' } : null)}
                        {...extraProps}
                    />
                    {rightLabel && (
                        <span className="input-group-addon">{rightLabel}</span>
                    )}
                </div>
            </UnitAppender>
        </FormGroup>
    )
}

export const fieldInputSmall = (options) => {
    const { title, input, unit, disabled, required, error } = options

    return (
        <FormGroup error={error} label={title} name={input.name} {...options}>
            <UnitAppender unit={unit}>
                <input
                    id={fieldId(input.name)}
                    type="text"
                    className="form-control string optional input-sm"
                    readOnly={disabled}
                    placeholder={options.placeholder}
                    {...processInputProps(input)}
                    {...(required ? { 'aria-required': 'true' } : null)}
                />
            </UnitAppender>
        </FormGroup>
    )
}

export const fieldTextarea = (options) => {
    const {
        title,
        input,
        meta,
        hint,
        unit,
        disabled,
        readOnly,
        compact,
        oneLine,
        required,
        error,
    } = options

    return (
        <FormGroup
            error={error}
            meta={meta}
            name={input.name}
            label={title}
            hint={hint}
            compact={compact}
            oneLine={oneLine}
            required={required}
        >
            <UnitAppender unit={unit}>
                <textarea
                    id={fieldId(input.name)}
                    rows={3}
                    className="form-control string optional"
                    disabled={disabled}
                    readOnly={readOnly}
                    {...input}
                    {...(required ? { 'aria-required': 'true' } : null)}
                />
            </UnitAppender>
        </FormGroup>
    )
}

function applyValueTransform(e, transform, onChange) {
    if (transform) {
        if (transform === 'positive') {
            onChange({
                target: { value: e.target.value.replace('-', '') },
            })

            return
        }

        if (transform === 'negative') {
            onChange({
                target: { value: '-' + e.target.value.replace('-', '') },
            })

            return
        }
    }

    onChange(e)
}

function normalizeTransformedValue(transform, value) {
    if (transform) {
        return value.toString().replace('-', '')
    }

    return value
}

/**
 * Adds value transformer + preprocesses value for displaying
 * @param input
 * @param transformValue
 * @returns {{}}
 */
function processTransformedInputProps(input, transformValue) {
    const processedProps = { ...processInputProps(input) }

    const inputOnChange = processedProps.onChange

    processedProps.onChange = (e) =>
        applyValueTransform(e, transformValue, inputOnChange)

    processedProps.value = normalizeTransformedValue(
        transformValue,
        processedProps.value
    )

    return processedProps
}

export const fieldInputNumber = (options) => {
    const {
        title,
        input,
        meta,
        hint,
        step,
        min,
        max,
        unit,
        disabled,
        readOnly,
        fullWidth,
        compact,
        small,
        oneLine,
        required,
        leftIcon,
        rightLabel,
        hideTitleAndHint,
        prefix,
        transformValue,
        error,
    } = options

    let inputClass = 'form-control string optional'

    if (small) {
        inputClass += ' input-sm'
    }

    const processedProps = processTransformedInputProps(input, transformValue)

    return (
        <FormGroup
            error={error}
            meta={meta}
            label={title}
            hint={hint}
            compact={compact}
            fullWidth={fullWidth}
            oneLine={oneLine}
            required={required}
            hideTitleAndHint={hideTitleAndHint}
            prefix={prefix}
            name={processedProps.name}
        >
            <UnitAppender unit={unit}>
                <div className={leftIcon || rightLabel ? 'input-group' : ''}>
                    {leftIcon && (
                        <span className="input-group-addon">
                            <span className={'fa fa-' + leftIcon} />
                        </span>
                    )}
                    <input
                        id={fieldId(processedProps.name)}
                        className={inputClass}
                        disabled={disabled}
                        readOnly={readOnly}
                        step="any"
                        placeholder={options.placeholder}
                        min={min}
                        max={max}
                        {...processedProps}
                        {...(required ? { 'aria-required': 'true' } : null)}
                        type="number"
                    />
                    {rightLabel && (
                        <span className="input-group-addon">{rightLabel}</span>
                    )}
                </div>
            </UnitAppender>
        </FormGroup>
    )
}

export const fieldInputAmount = (options) => {
    const {
        title,
        input,
        meta,
        hint,
        min,
        max,
        error,
        disabled,
        readOnly,
        fullWidth,
        compact,
        small,
        oneLine,
        required,
        leftIcon,
        hideTitleAndHint,
        transformValue,
        rightLabel,
    } = options

    const { app: { user_settings: { currency_symbol = '$' } = {} } = {} } =
        store.getState()
    let inputClass = 'form-control string optional'

    if (small) {
        inputClass += ' input-sm'
    }

    const processedProps = processTransformedInputProps(input, transformValue)

    return (
        <FormGroup
            error={error}
            meta={meta}
            label={title}
            hint={hint}
            compact={compact}
            fullWidth={fullWidth}
            oneLine={oneLine}
            required={required}
            hideTitleAndHint={hideTitleAndHint}
        >
            <UnitAppender unit={currency_symbol} pullLeft={true}>
                <div className={leftIcon || rightLabel ? 'input-group' : ''}>
                    {leftIcon && (
                        <span className="input-group-addon">
                            <span className={'fa fa-' + leftIcon} />
                        </span>
                    )}
                    <input
                        type="number"
                        className={inputClass}
                        disabled={disabled}
                        readOnly={readOnly}
                        step="any"
                        min={min}
                        max={max}
                        placeholder={options.placeholder}
                        {...processedProps}
                        {...(required ? { 'aria-required': 'true' } : null)}
                    />
                </div>
            </UnitAppender>
        </FormGroup>
    )
}

export const fieldInputAmountNumeric = (options) => {
    const {
        error,
        title,
        input,
        meta,
        hint,
        step,
        min,
        max,
        disabled,
        readOnly,
        fullWidth,
        compact,
        small,
        oneLine,
        required,
    } = options

    const { app: { user_settings: { currency_symbol = '$' } = {} } = {} } =
        store.getState()
    let inputClass = 'form-control string optional'

    if (small) {
        inputClass += ' input-sm'
    }

    return (
        <FormGroup
            meta={meta}
            label={title}
            hint={hint}
            compact={compact}
            fullWidth={fullWidth}
            oneLine={oneLine}
            required={required}
            name={input.name}
            error={error}
        >
            <UnitAppender unit={currency_symbol} pullLeft={true}>
                <input
                    id={fieldId(input.name)}
                    className={inputClass}
                    disabled={disabled}
                    readOnly={readOnly}
                    step="any"
                    min={min}
                    max={max}
                    placeholder={options.placeholder}
                    {...processInputProps(input)}
                    {...(required ? { 'aria-required': 'true' } : null)}
                    type="number"
                />
            </UnitAppender>
        </FormGroup>
    )
}

/**
 * loads option sources for state from DB
 */
async function loadStateIfNeeded(
    countryValue,
    countryField,
    form,
    field,
    updateValue
) {
    const {
        app: {
            option_sources: option_sources_app,
            entity: { option_sources: option_sources_entity } = {},
        },
    } = store.getState()

    if (updateValue) {
        // account for textFieldName being undefined
        const textFieldName = field.textFieldName
            ? field.textFieldName
            : field.input.name
        if (field.multiSelect) {
            form.change(textFieldName, [])
        } else {
            form.change(textFieldName, '')
        }
    }

    if (Array.isArray(countryValue)) {
        countryValue = String(countryValue.find((value) => !!value))
    }

    if (countryValue) {
        const countryKey = 'states_' + countryValue
        let optionSources = {
            ...option_sources_app,
            ...option_sources_entity,
        }

        const countryOptions = optionSources[countryKey]
        // load states?
        if (!countryOptions) {
            await store.dispatch(loadStates(countryValue))
        }

        if (!field.multiSelect) {
            const {
                app: {
                    option_sources: option_sources_app_reloaded,
                    entity: {
                        option_sources: option_sources_entity_reloaded,
                    } = {},
                },
            } = store.getState()

            optionSources = {
                ...option_sources_app_reloaded,
                ...option_sources_entity_reloaded,
            }
            const optionsList = optionSources[countryKey]
            if (optionsList) {
                if (optionsList.length > 0) {
                    if (updateValue) {
                        form.change(field.idFieldName, optionsList[0].value)
                        if (field.textFieldName) {
                            form.change(field.textFieldName, '')
                        }
                    }
                } else {
                    if (updateValue) {
                        form.change(field.idFieldName, '')
                    }
                }
            }
        }
    }
}

const CountryFieldRenderer = (props) => {
    const { options = {}, children } = props
    const { countryField } = options
    const [lastCountryValue, setLastCountryValue] = useState(0)
    const field = useField(options.countryField, {})
    const form = useForm()

    const loadState = async () => {
        if (!field) {
            return
        }

        const {
            input: { value },
        } = field

        if (value !== lastCountryValue) {
            setLastCountryValue(value)

            await loadStateIfNeeded(
                value,
                countryField,
                form,
                options,
                lastCountryValue !== 0
            )
        }
    }

    useEffect(() => {
        loadState()
    }, [field])

    return children
}

/**
 * Renders state field. Shows dropdown when options are not empty and text input otherwise
 */
export const fieldState = (options) => {
    const Control =
        options?.options?.length > 0
            ? fieldAssociation
            : options.small
              ? fieldInputSmall
              : fieldInput

    const body = <Control {...options} />

    if (options.countryField) {
        return (
            <CountryFieldRenderer options={options}>
                {body}
            </CountryFieldRenderer>
        )
    } else {
        return body
    }
}

export const fieldSelect = ({
    error,
    title,
    hint,
    options,
    input,
    meta,
    compact,
    small,
    disabled,
    fullWidth,
    oneLine,
    readOnly,
    multiSelect,
    forceSelect,
    defaultValue,
    skipNotExists,
    className: groupClassName,
    widgetWrapClass,
    size,
    required,
    updateOnChange,
    onlyOptions,
    hideTitleAndHint,
    prefix,
}) => {
    const [updatedFieldsState, setUpdatedFieldsState] = useState({})
    const form = useForm()
    const formState = useFormState()
    const optionList = renderOptions({
        value: input.value,
        options,
        onlyOptions,
    })
    const entityContext = useContext(EntityEditorContext)
    let className = 'form-control select'
    if (small) {
        className += ' input-sm'
    }

    const extraProps = {}

    if (multiSelect) {
        extraProps['multiple'] = 'multiple'
    }

    if (size && size > 0) {
        extraProps['size'] = size
    }

    // in case we've got an array as a value - convert to single value, since it's not supported by
    // the control
    if (!input.multiple && !multiSelect && Array.isArray(input.value)) {
        extraProps['value'] = input.value.length ? input.value[0] : ''
    }

    // pushes selected value into multiselect drop-down
    const performSetIn = () => {
        if (formState.values) {
            const currentValue = String(formState.values[input.name])
            let dstValue = formState.values[updateOnChange['$set_in']]
            if (!dstValue) {
                dstValue = []
            }

            let modified = false
            if (
                !dstValue.find(
                    (value) =>
                        currentValue === value || currentValue === String(value)
                )
            ) {
                dstValue.push(currentValue)
                modified = true
            }

            const customDataKey = '$set_in_' + input.name
            if (modified) {
                let oldValue = entityContext.getCustomData(customDataKey)
                if (oldValue && dstValue.length > 1) {
                    // remove old value from the list
                    dstValue = dstValue.filter(
                        (value) => String(value) !== String(oldValue)
                    )
                }
                form.change(updateOnChange['$set_in'], [...new Set(dstValue)])

                entityContext.setCustomData(customDataKey, currentValue)
            } else {
                // init custom data
                let oldValue = entityContext.getCustomData(customDataKey)
                if (!oldValue) {
                    entityContext.setCustomData(customDataKey, currentValue)
                }
            }
        }
    }

    const isExistsValue = isEmpty(input.value)
        ? true
        : !!input.value &&
          !!options?.find(
              (option) => String(option.value) === String(input.value)
          )

    const noInitialValue =
        !input.value && // no value selected
        !meta.touched && // not edited yet
        options &&
        options.length

    useEffect(() => {
        if (
            (forceSelect && noInitialValue) ||
            (skipNotExists && !isExistsValue)
        ) {
            const value = selectDropdownDefaultValue(
                forceSelect,
                options,
                formState.values
            )
            if (value) {
                form.change(input.name, value)
            }
        }

        if (updateOnChange && updateOnChange['$set_in']) {
            performSetIn()
        }
    }, [isExistsValue])

    useEffect(() => {
        // updated field values based on the value in this dropdown
        if (meta.modified && updateOnChange) {
            const valuesToUpdate =
                updateOnChange[input.value ? input.value.toString() : '']
            let fieldsUpdater

            if (updateOnChange['$set_in']) {
                // if this option is specified - we need to push the selected value into the target list
                fieldsUpdater = performSetIn
            } else if (valuesToUpdate) {
                fieldsUpdater = () => {
                    const newUpdatedFieldsState = {}
                    for (const [key, value] of Object.entries(valuesToUpdate)) {
                        const field = formState.values
                            ? formState.values[key]
                            : null
                        if (field) {
                            newUpdatedFieldsState[key] = field
                        }
                        form.change(key, value)
                    }

                    for (const [key, value] of Object.entries(
                        updatedFieldsState
                    )) {
                        if (!newUpdatedFieldsState[key]) {
                            form.change(key, value)
                        }
                    }

                    setUpdatedFieldsState({
                        ...updatedFieldsState,
                        ...newUpdatedFieldsState,
                    })
                }
            } else {
                fieldsUpdater = () => {
                    // restore values for fields which have null values
                    for (const [key, value] of Object.entries(
                        updatedFieldsState
                    )) {
                        const field = formState.values
                            ? formState.values[key]
                            : null
                        if (!field) {
                            form.change(key, value)
                        }
                    }
                }
            }

            if (fieldsUpdater) {
                setTimeout(() => form.batch(fieldsUpdater), 0)
            }
        }
    }, [input.value])

    return (
        <FormGroup
            error={error}
            meta={meta}
            label={title}
            hint={hint}
            compact={compact}
            fullWidth={fullWidth}
            oneLine={oneLine}
            className={groupClassName}
            widgetWrapClass={widgetWrapClass ? widgetWrapClass : ''}
            required={required}
            name={input.name}
            hideTitleAndHint={hideTitleAndHint}
            prefix={prefix}
        >
            <select
                {...input}
                className={className}
                id={fieldId(input.name)}
                disabled={disabled || readOnly}
                defaultValue={defaultValue}
                {...extraProps}
                {...(required ? { 'aria-required': 'true' } : null)}
                value={
                    !!input.value && !isEmpty(String(input.value))
                        ? input.value
                        : null
                }
            >
                {optionList}
            </select>
        </FormGroup>
    )
}

/**
 * @param value - can be boolean or 'true', 'false' or the name of some field
 * @returns {boolean|any}
 */
function useFlagReference(value) {
    if (typeof value === 'boolean') {
        return value
    }

    if (typeof value === 'string') {
        if (value === 'true') {
            return true
        }
        if (value === 'false') {
            return false
        }

        const form = useForm()
        const values = form.getState().values
        return values[value]
    }

    return !!value
}

export const fieldAssociation = (props) => {
    const {
        className,
        error,
        title,
        extraHint,
        hint,
        options: initialOptions,
        onlyOptions = [],
        optionGroups,
        input,
        meta,
        disabled,
        readOnly,
        compact,
        multiSelect,
        forceSelect,
        emptyMessage,
        askToSelect,
        tooltip,
        oneLine,
        size,
        required,
        searchable,
        allowCreate,
        modal,
        entityName,
        onFocus,
        onBlur,
        placeholder,
        updateOnChange,
        onCreateOption,
        hideTitleAndHint,
        prefix,
    } = props

    const options =
        initialOptions?.filter(
            (option) =>
                (input.value && input.value === option.value) ||
                !onlyOptions?.length ||
                onlyOptions.includes(option.value)
        ) || []

    let metaData = { ...meta }
    if (
        forceSelect &&
        (!options || options.length === 0 || input.value === '')
    ) {
        metaData.error = emptyMessage
        metaData.touched = true
    }

    if (modal) {
        return (
            <ModalMultiselect
                className={className}
                disabled={disabled || readOnly}
                /* $FlowFixMe */
                hint={hint}
                /* $FlowFixMe */
                options={options}
                title={title}
                onChange={input.onChange}
                multiSelect={multiSelect}
                selected={input.value}
                forceSelect={forceSelect}
                askToSelect={askToSelect}
                tooltip={tooltip}
                required={required}
                meta={metaData}
                entityName={entityName}
                missingDataNote={props.missingDataNote}
            />
        )
    } else if (optionGroups) {
        return fieldOptionGroups(props)
    } else if (searchable && multiSelect) {
        const extraProps = {}

        const isDisabled =
            disabled ||
            useFlagReference(
                readOnly !== 'undefined'
                    ? readOnly
                    : disabled !== 'undefined'
                      ? disabled
                      : false
            )

        if (props.highlightValue) {
            const form = useForm()
            const values = form.getState().values
            if (values) {
                const fieldValue = values[props.highlightValue]
                extraProps.highlightValue = fieldValue
                extraProps.highlightValuePrefix = props.highlightValuePrefix
            }
        }

        const passExtraHint = options.length > 100 ? extraHint : undefined

        return (
            <FormGroup
                className={className}
                error={error}
                meta={metaData}
                label={title}
                hint={hint}
                extraHint={passExtraHint}
                compact={compact}
                oneLine={oneLine}
                required={required}
                name={input.name}
                hideTitleAndHint={hideTitleAndHint}
                prefix={prefix}
            >
                <CreateNewContext.Consumer>
                    {(context) => (
                        <DropdownWithSearch
                            id={fieldId(input.name)}
                            disabled={isDisabled}
                            /* $FlowFixMe */
                            options={options}
                            name={input.name}
                            onChange={(value) => {
                                const inputValue = value?.filter(
                                    (item) => item !== '' && item !== null
                                )

                                input.onChange(inputValue)
                            }}
                            multiSelect={multiSelect}
                            emptyMessage={emptyMessage}
                            selected={input.value}
                            forceSelect={forceSelect}
                            askToSelect={askToSelect}
                            tooltip={tooltip}
                            allowCreate={allowCreate}
                            onCreateNew={
                                onCreateOption
                                    ? onCreateOption
                                    : () => context.create(props)
                            }
                            onFocus={onFocus}
                            onBlur={onBlur}
                            placeholder={placeholder}
                            {...extraProps}
                        />
                    )}
                </CreateNewContext.Consumer>
            </FormGroup>
        )
    } else if (multiSelect && !size) {
        return (
            <FormGroup
                className={className}
                error={error}
                meta={metaData}
                label={title}
                hint={hint}
                compact={compact}
                oneLine={oneLine}
                required={required}
                hideTitleAndHint={hideTitleAndHint}
                prefix={prefix}
            >
                <CreateNewContext.Consumer>
                    {(context) => (
                        <Dropdown
                            disabled={disabled || readOnly}
                            /* $FlowFixMe */
                            options={options}
                            onChange={input.onChange}
                            multiSelect={multiSelect}
                            selected={input.value}
                            forceSelect={forceSelect}
                            askToSelect={askToSelect}
                            tooltip={tooltip}
                            allowCreate={allowCreate}
                            onCreateNew={() => context.create(props)}
                        />
                    )}
                </CreateNewContext.Consumer>
            </FormGroup>
        )
    } else {
        return fieldSelect(props)
    }
}

function renderOptions({ value, options, onlyOptions = [] }) {
    return (options || [])
        .filter(
            (option) =>
                value === option.value ||
                !onlyOptions.length ||
                onlyOptions.includes(option.value)
        )
        .map((option) =>
            Array.isArray(option.value) ? (
                <optgroup label={option.title}>
                    {renderOptions(option.value)}
                </optgroup>
            ) : option.value === null ? (
                <option disabled selected value>
                    {`${option.title} ${option.url ? `- ${option.url}` : ''}`}
                </option>
            ) : (
                <option key={option.value} value={option.value}>
                    {`${option.title} ${option.url ? `- ${option.url}` : ''}`}
                </option>
            )
        )
}

export const fieldOptionGroups = (props) => {
    const {
        input,
        meta,
        title,
        hint,
        compact,
        disabled,
        oneLine,
        multiSelect,
        options,
        size,
        required,
        error,
    } = props

    const groups = options
        ? options.map((group) => {
              if (!Array.isArray(group.options)) {
                  return (
                      <option key={group.value} value={group.value}>
                          {group.title}
                      </option>
                  )
              }

              return (
                  <optgroup key={group.title} label={group.title}>
                      {renderOptions({ options: group.options })}
                  </optgroup>
              )
          })
        : []

    const extraOpts = {}

    if (multiSelect) {
        extraOpts.multiple = 'multiple'
    }

    return (
        <FormGroup
            error={error}
            meta={meta}
            name={input.name}
            label={title}
            hint={hint}
            compact={compact}
            oneLine={oneLine}
            required={required}
        >
            <select
                id={fieldId(input.name)}
                className="form-control select optional"
                disabled={disabled}
                {...input}
                {...extraOpts}
                size={multiSelect ? (size ? size : 15) : 1}
                {...(required ? { 'aria-required': 'true' } : null)}
            >
                {groups}
            </select>
        </FormGroup>
    )
}

export const fieldYesNo = ({
    input,
    meta,
    title,
    hint,
    compact,
    disabled,
    oneLine,
    required,
    error,
}) => {
    return (
        <FormGroup
            error={error}
            name={input.name}
            meta={meta}
            label={title}
            hint={hint}
            compact={compact}
            oneLine={oneLine}
            required={required}
        >
            <select
                id={fieldId(input.name)}
                className="form-control select optional"
                disabled={disabled}
                {...input}
                {...(required ? { 'aria-required': 'true' } : null)}
            >
                <option value="true">Yes</option>
                <option value="false">No</option>
            </select>
        </FormGroup>
    )
}

/**
 * @deprecated
 */
export const fieldCheckbox = (options) => {
    const {
        title,
        input,
        meta,
        hint,
        unit,
        disabled,
        compact,
        oneLine,
        required,
        error,
    } = options

    return (
        <FormGroup
            error={error}
            meta={meta}
            name={input.name}
            label={title}
            hint={hint}
            compact={compact}
            oneLine={oneLine}
            required={required}
        >
            <UnitAppender unit={unit}>
                <input
                    id={fieldId(input.name)}
                    type="checkbox"
                    className="form-control string optional"
                    disabled={disabled}
                    {...input}
                    {...(required ? { 'aria-required': 'true' } : null)}
                />
            </UnitAppender>
        </FormGroup>
    )
}

export const fieldCheckboxRC = ({
    input,
    title,
    disabled,
    className = null,
    required,
}) => {
    return (
        <div className={'checkbox' + (className ? ' ' + className : '')}>
            <label className={`control-label optional ${styles.checkboxLabel}`}>
                <input
                    className="form-control optional"
                    disabled={disabled}
                    type="checkbox"
                    {...input}
                    {...(required ? { 'aria-required': 'true' } : null)}
                />{' '}
                <div className="toggle">{title}</div>
            </label>
        </div>
    )
}

export const fieldCheckboxStd = ({
    input,
    meta,
    title,
    hint,
    compact,
    disabled,
    className,
    fullWidth,
    oneLine,
    required,
    readOnly,
    updateOnChange,
    tooltip,
    renderTitle,
    error,
    bold = true,
}) => {
    const form = useForm()
    const inputId = fieldId(input.name)
    const labelBody = renderTitle ? renderTitle() : title
    return (
        <FormGroup
            error={error}
            meta={meta}
            hint={hint}
            compact={compact}
            fullWidth={fullWidth}
            className={className}
            oneLine={oneLine}
            required={required}
            removeFieldDiv={true}
        >
            <div className="form-check">
                <input
                    id={inputId}
                    className="form-check-input optional"
                    disabled={disabled || readOnly}
                    type="checkbox"
                    {...input}
                    {...(required ? { 'aria-required': 'true' } : null)}
                    onChange={(e) => {
                        if (updateOnChange) {
                            // update some fields if needed...
                            const value = e.target.checked
                            if (value in updateOnChange) {
                                form.batch(() => {
                                    for (const [key, val] of Object.entries(
                                        updateOnChange[value]
                                    )) {
                                        form.change(key, val)
                                    }
                                })
                            }
                        }

                        return input.onChange(e)
                    }}
                    title={tooltip}
                />
                <label
                    className={classnames('form-check-label optional', {
                        bold,
                    })}
                    htmlFor={inputId}
                >
                    {labelBody}
                </label>
            </div>
        </FormGroup>
    )
}

export const fieldRadio = (props) => {
    const { input, title, disabled, labelClassName, required } = props
    const className = labelClassName ? labelClassName : 'control-label optional'

    const labelFor =
        fieldId(input.name) +
        '-' +
        String(input.value)
            .toLowerCase()
            .replace(/[^\w]+/i, '-')

    return (
        <RadioInput
            className={className}
            labelFor={labelFor}
            disabled={disabled}
            required={required}
            title={title}
            {...input}
        />
    )
}

export const fieldRadioList = (props) => {
    const {
        title,
        input,
        meta,
        hint,
        unit,
        disabled,
        compact,
        oneLine,
        required,
        options,
    } = props

    return (
        <FormGroup
            rootClassName=""
            labelClassName="form-group-title"
            meta={meta}
            name={input.name}
            label={title ? `${title}:` : null}
            hint={hint}
            compact={compact}
            oneLine={oneLine}
            required={required}
        >
            <UnitAppender unit={unit}>
                {options.map((option) =>
                    fieldRadio({
                        input: {
                            ...input,
                            value: option.value,
                            checked: input.value === option.value,
                        },
                        title: option.title,
                        disabled,
                        required,
                    })
                )}
            </UnitAppender>
        </FormGroup>
    )
}

export const fieldInternalCode = (props) => {
    const {
        title,
        input,
        meta,
        hint,
        disabled,
        compact,
        fullWidth,
        className,
        oneLine,
        input: { type },
        readOnly,
        required,
        error,
    } = props

    let value = input.value
    if (value.indexOf('shq') === 0) {
        value = input.value.substr(3)
    }

    return (
        <FormGroup
            error={error}
            name={input.name}
            meta={meta}
            label={title}
            hint={hint}
            compact={compact}
            fullWidth={fullWidth}
            className={className}
            oneLine={oneLine}
            required={required}
        >
            <UnitAppender unit="shq" pullLeft={true}>
                <input
                    id={fieldId(input.name)}
                    className="form-control string optional"
                    type={type ? type : 'text'}
                    disabled={disabled}
                    readOnly={readOnly}
                    {...input}
                    value={value}
                    {...(required ? { 'aria-required': 'true' } : null)}
                />
            </UnitAppender>
        </FormGroup>
    )
}

const FieldMethodSpy = (props) => {
    const {
        fieldProps: {
            options,
            meta,
            input,
            dataSourceIdField,
            dataSourceField,
        },
    } = props

    const [valuesInState, setValues] = useState({ values: {} })
    const form = useForm()

    useEffect(() => {
        const { values } = valuesInState
        if (options && dataSourceIdField) {
            const newValue = values[dataSourceIdField]
            input.onChange(options[newValue])
        } else if (
            dataSourceField &&
            !meta.touched &&
            !meta.visited &&
            !(values._created || values.id > 0)
        ) {
            const newValue = values[dataSourceField]
            if (newValue !== undefined) {
                form.change(input.name, newValue.replace(/\s/g, '_'))
            }
        } else {
            const newValue = values[input.name]
            // method code was edited and contains a space character?
            if (newValue !== undefined && newValue.includes(' ')) {
                form.change(input.name, newValue.replace(/\s/g, '_'))
            }
        }
    }, [valuesInState])

    return <FormSpy onChange={(values) => setValues(values)} />
}

export const fieldMethodCode = (props) => {
    const {
        title,
        hint,
        input,
        meta,
        compact,
        oneLine,
        dataSourceIdField,
        required,
        error,
    } = props

    let metaData = { ...meta }
    const extraProps = {}
    if (dataSourceIdField) {
        extraProps.disabled = 'disabled'
    }

    return (
        <FormGroup
            error={error}
            meta={metaData}
            label={title}
            hint={hint}
            name={input.name}
            compact={compact}
            oneLine={oneLine}
            required={required}
        >
            <input
                type="text"
                {...input}
                value={input.value ? input.value : ''}
                className="form-control string optional"
                id={fieldId(input.name)}
                {...extraProps}
                {...(required ? { 'aria-required': 'true' } : null)}
            />
            <FieldMethodSpy fieldProps={props} />
        </FormGroup>
    )
}

export const fieldHidden = (options) => {
    const { input, setValue } = options
    const form = useForm()
    // when mounted - change field value to what was passed in value parameter (we are passing it in setValue, since value is not passed from <Field>)
    useEffect(() => {
        form.change(input.name, setValue)
    }, [])

    return <input {...processInputProps(input)} type="hidden" />
}

/**
 * Invoked when field label is clicked
 * @param e
 */
function focusOnControl(e) {
    /* $FlowFixMe */
    if (e.target && e.target.nextSibling) {
        const matchedElement = e.target.nextSibling.querySelector(
            'input, select, textarea'
        )
        if (matchedElement) {
            if (
                matchedElement.tagName === 'INPUT' &&
                matchedElement.type === 'checkbox'
            ) {
                matchedElement.click()
            } else {
                matchedElement.focus()
            }
        }
    }
}

function processInputProps(inputProps) {
    if (inputProps.value || inputProps.value === 0) {
        return inputProps
    }

    return { ...inputProps, value: '' }
}

export function fieldId(inputName) {
    return 'field-' + inputName
}
