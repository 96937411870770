//
import React, { useRef, useState, useEffect } from 'react'
import cx from 'classnames'

/**
 * ScrollableList Properties
 */

export default function ScrollableList({
    children,
    listClass = 'list',
    scrollClass = 'with-scrollbar',
    maxHeight = 400,
}) {
    const listRef = useRef()
    const [showScrollBar, setScrollBarVisible] = useState(false)

    useEffect(() => {
        const { current: listEl = null } = listRef
        if (listEl) {
            // show scroll bar when height > 400px
            if (listEl.scrollHeight > maxHeight) {
                setScrollBarVisible(true)
                return
            }
        }

        setScrollBarVisible(false)
    }, [listRef, children])

    return (
        <div
            className={cx(listClass, {
                [scrollClass]: showScrollBar,
            })}
            ref={listRef}
        >
            {children}
        </div>
    )
}
