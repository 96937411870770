import styled from 'styled-components'

const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    background-color: ${({ theme }) => theme.colors.dkblue1};
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    i {
        color: ${({ theme }) => theme.colors.white};
        font-size: 24px;
    }
`

const SubIcon = styled.span``

const Title = styled.h4`
    font-weight: 700;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.grey3};
    margin: 0;
    text-align: left;
`

const RequiredTitle = styled.h3`
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 26px;
    margin: 0;
    text-align: left;
    color: ${({ theme }) => theme.colors.orange};
`

const Note = styled.p`
    font-weight: 400;
    line-height: 26px;
    color: ${({ theme }) => theme.colors.greybluedk};
    margin: 0;
    text-align: left;
`

const FieldDescription = styled.p`
    font-weight: 500;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.dkblue2};
    margin: 0;
    text-align: left;
`

const GoalItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 1px;
    padding: 12px;
    color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    width: 100%;
    transition: background-color 0.4s ease;
    background-color: ${({ theme }) => theme.colors.white};

    &.checked {
        border: ${({ theme }) => `2px solid ${theme.colors.dkblue1}`};
        margin: 0;
        background-color: ${({ theme }) => theme.colors.white};
    }

    ${SubIcon} {
        margin-right: 10px;

        i {
            font-size: 14px;
            color: ${({ theme }) => theme.colors.dkblue1};
        }
    }

    &.disabled {
        &:hover {
            background-color: ${({ theme }) => theme.colors.white};
        }

        ${Icon} {
            background-color: ${({ theme }) => theme.colors.greyb};
        }

        ${Title} {
            color: ${({ theme }) => theme.colors.greybluelt4};
        }

        ${Note} {
            color: ${({ theme }) => theme.colors.greybluelt4};
        }
    }

    &.mb-3 {
        margin-bottom: 12px;
    }

    &.navigateble {
        ${SubIcon} i {
            font-size: 14px;
            color: ${({ theme }) => theme.colors.dkblue1};
        }
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            background-color: ${({ theme }) => theme.colors.greyfa};
        }
    }

    &.warning {
        ${SubIcon} i {
            color: ${({ theme }) => theme.colors.yellowdk2};
            font-size: 24px;
        }
    }

    &.success {
        ${SubIcon} i {
            color: ${({ theme }) => theme.colors.green1};
            font-size: 24px;
        }
    }

    &.variantCarrier {
        ${Icon} {
            background-color: ${({ theme }) => theme.colors.white};

            & > div {
                border-radius: 6px;
                width: 100%;
                height: 100%;
                background-size: 70px !important;
                background-repeat: no-repeat !important;
                background-position: center center !important;
            }
        }
    }

    &.sizeSmall {
        ${Title} {
            font-size: 16px;
        }

        ${Note} {
            font-size: 16px;
        }

        ${FieldDescription} {
            font-size: 14px;
        }

        ${Icon} {
            width: 56px;
            height: 56px;
        }
    }

    &.sizeMedium {
        ${Title} {
            font-size: 18px;
        }

        ${Note} {
            font-size: 16px;
        }

        ${FieldDescription} {
            font-size: 14px;
        }

        ${Icon} {
            width: 72px;
            height: 72px;
        }
    }
`

const InputLabels = styled.div`
    display: flex;
    flex-direction: column;

    & > label {
        margin-bottom: 14px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    input {
        display: none;
    }

    input:checked ~ ${GoalItem} {
        border: ${({ theme }) => `2px solid ${theme.colors.dkblue1}`};
        margin: 0;
        background-color: ${({ theme }) => theme.colors.white};
    }

    input[disabled] ~ ${GoalItem} {
        cursor: auto;

        ${Title} {
            color: #bbbbbb;
        }

        i {
            color: #bbbbbb;
        }
    }
`

const SuccessCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: ${({ theme }) => theme.colors.greenlt1};
    border: ${({ theme }) => `1px solid ${theme.colors.greenlt2}`};
    border-radius: 6px;

    ${GoalItem} {
        margin-bottom: 14px;
        cursor: initial;
        background: ${({ theme }) => theme.colors.white};

        &:last-child {
            margin-bottom: 0px;
        }
    }
`

export const ShortCuts = {
    InputLabels,
    SuccessCard,
    Icon,
    SubIcon,
    Title,
    RequiredTitle,
    Note,
    FieldDescription,
    GoalItem,
}
