import { useQuery, useMutation, useQueryClient } from 'react-query'
import { axiosClient } from 'net/ajax'

async function getToken(websiteId, integrationType) {
    const response = await axiosClient.get(
        `/ratesmgr/integrations/tokens?website_id=${websiteId}&integrations[integration_type]=${integrationType}`
    )

    return response.data.tokens?.[0]?.token
}

export function useGetToken({ websiteId, integrationType }) {
    const { data: token, isLoading: isGettingToken } = useQuery(
        [`tokens`, integrationType, websiteId],
        () => getToken(websiteId, integrationType)
    )

    return {
        token,
        isGettingToken,
    }
}

async function deleteTokenAsync({ websiteId, token }) {
    const response = await axiosClient.delete(
        `/ratesmgr/integrations/tokens?website_id=${websiteId}&token=${token}`
    )
    return response.data
}

export function useDeleteToken() {
    const queryClient = useQueryClient()
    const { isLoading: isDeletingToken, mutate: deleteToken } = useMutation(
        (deleteParams) => deleteTokenAsync(deleteParams),
        {
            onSuccess: (_data, deleteParams) => {
                queryClient.invalidateQueries('tokens')
                queryClient.invalidateQueries(deleteParams.websiteId)
            },
        }
    )

    return {
        deleteToken,
        isDeletingToken,
    }
}

async function createToken({ websiteId, integrationType }) {
    const response = await axiosClient.post('/ratesmgr/integrations/tokens', {
        website_id: websiteId,
        integrations: { integration_type: integrationType },
    })
    return response.data.token
}

export function useCreateToken() {
    const queryClient = useQueryClient()
    const {
        isLoading: isFetchingToken,
        data: token,
        mutate: create,
    } = useMutation(createToken, {
        onSuccess: (_data, websiteId) => {
            queryClient.invalidateQueries('tokens')
            queryClient.invalidateQueries(websiteId)
        },
    })

    return {
        token,
        create,
        isFetchingToken,
    }
}
