//
import React, { Component, Fragment } from 'react'
import DropdownItem from './DropdownItem'

function isOptionSelected(selected, value) {
    if (Array.isArray(selected)) {
        return selected.includes(value)
    } else {
        if (!selected && value === '') {
            return true
        }

        return selected === value
    }
}

export default class Dropdown extends Component {
    wrapperRef

    static defaultProps = {
        disabled: false,
        onChange: () => {},
    }

    state = {
        opened: false,
        selected: [],
    }

    constructor(props) {
        super(props)

        this.wrapperRef = React.createRef()
        this.state = {
            opened: false,
            selected: props.selected,
        }

        this.onCreateNew = this.onCreateNew.bind(this)
    }

    componentDidMount() {
        const { selected, forceSelect, options } = this.props
        if (!selected && forceSelect) {
            this.select(options[0].value)
        }
    }

    static getDerivedStateFromProps(props, state) {
        // userInput is present in the state when user changed the selected value
        if (state.userInput) {
            return {
                ...state,
                selected: state.selected,
            }
        } else {
            return {
                ...state,
                selected: props.selected,
            }
        }
    }

    toggle = () => {
        this.setState({
            opened: !this.state.opened,
        })
    }

    hide = () => {
        this.setState({
            opened: false,
        })
    }

    onCreateNew() {
        const { onCreateNew } = this.props

        this.setState({
            opened: false,
        })

        if (onCreateNew) {
            onCreateNew()
        }
    }

    select = (value) => {
        const { multiSelect } = this.props

        if (multiSelect) {
            const { selected } = this.state
            let result = selected
                ? Array.from(selected).filter((item) => item !== value)
                : []

            if (!selected || !selected.includes(value)) {
                result.push(value)
            }

            let newState = {
                selected: result,
                userInput: true,
            }

            this.setState(newState)

            if (this.props.onChange) {
                this.props.onChange(result.length > 0 ? result : [''])
            }
        } else {
            this.setState({
                selected: value,
                opened: false,
                userInput: true,
            })

            if (this.props.onChange) {
                this.props.onChange(value)
            }
        }
    }

    handleClickOutside = (event) => {
        if (
            this.wrapperRef &&
            this.wrapperRef.current &&
            !this.wrapperRef.current.contains(event.target)
        ) {
            this.hide()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.opened && !this.state.opened) {
            document.removeEventListener('mousedown', this.handleClickOutside)
        } else if (!prevState.opened && this.state.opened) {
            document.addEventListener('mousedown', this.handleClickOutside)
        }
    }

    getValueCmp(values) {
        const { multiSelect } = this.props

        if (multiSelect) {
            return (option) => values && values.includes(option.value)
        }

        const value = Array.isArray(values) ? values.join('') : values

        return (option) => {
            return value === option.value
        }
    }

    getTitleByValue = (values) => {
        const { multiSelect } = this.props
        if (!this.props.options) {
            return ''
        }

        const options = this.props.options
            ? this.props.options.filter(this.getValueCmp(values))
            : []

        if (options && options.length > 0) {
            return options.map((option) => option.title).join(', ')
        } else {
            const { askToSelect } = this.props

            if (askToSelect && askToSelect.length > 0) {
                return askToSelect
            }

            if (multiSelect) {
                return 'N/A'
            } else {
                return ''
            }
        }
    }

    render() {
        let divClass =
            'btn-group bootstrap-select show-tick form-control select optional multiselect-ignore'
        const { opened, selected } = this.state
        const { options, disabled, tooltip, allowCreate } = this.props

        if (opened) {
            divClass += ' open'
        }

        return (
            <div className={divClass} ref={this.wrapperRef}>
                <button
                    type="button"
                    className="btn dropdown-toggle selectpicker btn-default"
                    title={tooltip}
                    onClick={this.toggle}
                    disabled={disabled}
                >
                    <span className="filter-option pull-left">
                        {this.getTitleByValue(selected)}
                    </span>
                    &nbsp;
                    <span className="caret" />
                </button>
                <div className="dropdown-menu open">
                    <ul
                        className="dropdown-menu inner selectpicker"
                        role="menu"
                    >
                        {options &&
                            options.map((option, idx) => (
                                <DropdownItem
                                    key={idx}
                                    title={option.title}
                                    value={option.value}
                                    selected={isOptionSelected(
                                        selected,
                                        option.value
                                    )}
                                    onSelect={this.select}
                                />
                            ))}
                        {allowCreate && (
                            <Fragment>
                                <DropdownItem delimiter={true} />
                                <DropdownItem
                                    key="add_new"
                                    title="Add New"
                                    onSelect={this.onCreateNew}
                                />
                            </Fragment>
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}
