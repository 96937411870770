import { useSelector } from 'react-redux'
import useFeaturesPlans from 'hooks/useFeaturesPlans'
import { toTitleCase, itemsToString } from 'utils/string'

import iconsMapper from './Icons'

// TODO: Refactoring. Now platform plans list is static html
export const AVAILABLE_PLATFORM_PLANS = {
    Bigcommerce: [
        'essentials',
        'starter',
        'standard',
        'advanced',
        'enterprise',
    ],
    Magento: ['standard', 'advanced', 'enterprise'],
    'Magento 2': ['standard', 'advanced', 'enterprise'],
    Salesforce: ['enterprise'],
    Shopify: ['essentials', 'starter', 'standard', 'advanced', 'enterprise'],
    Woocommerce: [
        'essentials',
        'starter',
        'standard',
        'advanced',
        'enterprise',
    ],
    Zoey: ['standard', 'advanced', 'enterprise'],
    AdobeCommerce: ['standard', 'advanced'],
    'Cart.com': ['advanced', 'enterprise'],
    Commercetools: ['enterprise'],
}

export default function useFeatures() {
    const {
        current_user: currentUser,
        features = [],
        features_limit: featuresLimit,
        enabled_features: enabledFeatures,
        available_features: availableFeatures,
        upselling_features: upsellingFeatures,
        upsell_ads_plans: upsellAdsPlans,
        plan,
    } = useSelector<ReduxRootState, ReduxAppData>(({ app }) => app)

    const pinnedPopular = currentUser.shipcal_full_conversion
        ? ['dropship', 'dimship', 'shipcal']
        : ['shipcal', 'dropship', 'dimship']

    const popularFeatures = [
        ...pinnedPopular,
        ...(upsellAdsPlans?.plans_popular_features?.[plan] || []),
    ]

    const { isLegacyCrossBorder } = useCrossBorderSettings()

    const availableFeaturesMap = new Set(availableFeatures)
    const upsellingFeaturesMap = new Set(upsellingFeatures)
    const enabledFeaturesMap = new Set(enabledFeatures)
    const popularFeaturesMap = new Set(popularFeatures)
    const addonFeatures = new Set(['cross_border'])
    const userIsOnTrial = currentUser.is_on_trial

    const isAvailable = (name) => availableFeaturesMap.has(name)
    const isUpsell = (name) => upsellingFeaturesMap.has(name)
    const isEnabled = (name) => enabledFeaturesMap.has(name)
    const isPopular = (name) => popularFeaturesMap.has(name)
    const isAddon = (name) => addonFeatures.has(name)

    const isShipcalConversion = (name) =>
        name === 'shipcal' && !currentUser.shipcal_full_conversion

    const fetchPlanCategory = (name) => {
        if (isPopular(name)) {
            return 'popular'
        }

        if (isAddon(name)) {
            return 'addon'
        }

        return isAvailable(name) ? 'available' : 'restricted'
    }

    const requiresBillling = (name) => {
        if (name === 'cross_border' && !isLegacyCrossBorder)
            return userIsOnTrial

        return false
    }

    const buildFeaturePresenter = (name, feature) => ({
        ...feature,
        name,
        key: name,
        icon: iconsMapper(name),
        order: feature.order,
        title: feature.title,
        title_lc: feature.title?.toLocaleLowerCase(),
        description: feature.description,
        description_lc: feature.description?.toLocaleLowerCase(),
        shortDescription: isShipcalConversion(name)
            ? feature.ad_description
            : feature.short_description,
        isLocked: !isAvailable(name) || requiresBillling(name),
        isUpsell: isUpsell(name),
        isEnabled: isEnabled(name),
        isPopular: isPopular(name),
        isComingSoon: feature.coming_soon,
        category: fetchPlanCategory(name),
        isAbTest: isShipcalConversion(name),
        isNew: !!feature.is_new,
    })

    const isEmptyFeatures = !availableFeatures || !availableFeatures.length

    const formatAndGroupFeatures = () => {
        // If a feature doesn't have a description
        // then we don't want to render it
        const validFeatures = Object.entries(features).filter(
            ([name, feature]) =>
                isEmptyFeatures
                    ? true
                    : (isAvailable(name) || isUpsell(name)) &&
                      feature.description
        )
        const validFormattedFeatures = validFeatures.map(([name, feature]) =>
            buildFeaturePresenter(name, feature)
        )

        const sortedValidFormattedFeatures = [
            ...validFormattedFeatures.sort((featureA, featureB) =>
                compareFeatures(featureA, featureB, { pinnedPopular })
            ),
        ]

        return sortedValidFormattedFeatures
    }

    // console.log('enabledFeatures', enabledFeatures)
    const featuresToLimit = enabledFeatures.filter(
        (item) => !addonFeatures.has(item)
    )
    const limitUsed = featuresToLimit.length

    return {
        features,
        featuresLimit,
        enabledFeatures,
        availableFeatures,
        upsellingFeatures,
        isOverTheLimit: limitUsed >= featuresLimit,
        limitUsed,
        isEmptyFeatures,
        formatAndGroupFeatures,
        buildFeaturePresenter,
        isAvailable,
        isEnabled,
        userIsOnTrial,
    }
}

/**
 * Sorts features using order field
 * @param features
 */
function compareFeatures(featureA, featureB, { pinnedPopular }) {
    const popularIndexA = pinnedPopular.indexOf(featureA.name)
    const popularIndexB = pinnedPopular.indexOf(featureB.name)

    // need to sort popular features by "pinnedPopular" list
    if (popularIndexA !== -1 && popularIndexB !== -1) {
        return popularIndexA - popularIndexB
    }

    // make sure that locked features are sorted last
    if (!featureA.isLocked && featureB.isLocked) {
        return -1
    }
    if (featureA.isLocked && !featureB.isLocked) {
        return 1
    }
    // if both features are locked or unlocked, sort like normal
    return featureA.order - featureB.order
}

export type AbstractFeatureName = 'advancedAnalytics'

export const useFeatureUpsellPlans = ({
    name,
    abstractFeatureName,
}: {
    name?: string
    abstractFeatureName?: AbstractFeatureName
}) => {
    const { featuresPlans } = useFeaturesPlans()

    const {
        platform,
        plan,
        advanced_analytics_plans: advancedAnalyticsPlans,
    } = useSelector<ReduxRootState, ReduxAppData>(({ app }) => app)

    let featurePlans = []

    if (name) {
        featurePlans = featuresPlans[name] || []
    } else if (abstractFeatureName == 'advancedAnalytics') {
        featurePlans = advancedAnalyticsPlans
    } else {
        throw new Error('Feature name is required')
    }

    const availablePlans = AVAILABLE_PLATFORM_PLANS[platform] || []

    const availableFeaturePlans = availablePlans.filter((availablePlan) =>
        featurePlans.includes(availablePlan)
    )

    const upsellPlans = availableFeaturePlans.slice(
        availableFeaturePlans.indexOf(plan) + 1,
        availableFeaturePlans.length + 1
    )

    return upsellPlans
}
export const useFeatureRestriction = ({ name, isLocked, isEnabled }) => {
    const { isOverTheLimit, userIsOnTrial } = useFeatures()
    const { isLegacyCrossBorder } = useCrossBorderSettings()

    const upsellPlans = useFeatureUpsellPlans({ name })

    if (name === 'cross_border' && userIsOnTrial && !isLegacyCrossBorder) {
        return {
            restrictionReason:
                'Not available on trial. Set up billing to enable',
            isFeatureLimit: false,
            isUpgradeLimit: false,
            isBillingRequired: true,
        }
    }

    const upsellPlansString = itemsToString(
        upsellPlans.map((plan) => toTitleCase(plan)),
        'and'
    )

    const isFeatureLimit = !isEnabled && isOverTheLimit && !isLocked
    const isUpgradeLimit = !isEnabled && isLocked

    const upgradeLimitMessage = `Available on ${upsellPlansString} ${
        upsellPlans.length > 1 ? 'plans' : 'plan'
    }`

    const restrictionReason = isEnabled
        ? null
        : isFeatureLimit
          ? 'Feature limit reached'
          : isUpgradeLimit
            ? upgradeLimitMessage
            : null

    return { restrictionReason, isFeatureLimit, isUpgradeLimit }
}

export const useCrossBorderSettings = () => {
    const { cross_border_settings } = useSelector<ReduxRootState, ReduxAppData>(
        ({ app }) => app
    )

    const isLegacy = cross_border_settings?.is_legacy

    return {
        isLegacyCrossBorder: isLegacy,
    }
}
