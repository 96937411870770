import React from 'react'

export default function LoaderIcon() {
    return (
        <div
            data-testid="loading-spinner"
            style={{
                position: 'fixed',
                zIndex: '1000',
                background: '#fff',
                opacity: 0.75,
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <i
                className="fa fa-spinner fa-spin"
                aria-hidden="true"
                style={{
                    color: '#008cbe',
                    fontSize: '64px',
                }}
            />
        </div>
    )
}
