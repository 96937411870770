import { getCategoryTitle } from './categories'

export function logoStyle(logos, name, suffix = 'Bg') {
    if (!logos) {
        return {}
    }

    const bgKey = name + suffix
    let result = {},
        nonempty = false
    if (logos[bgKey]) {
        nonempty = true
        result.background = logos[bgKey]
    }

    return nonempty ? { style: result } : {}
}

export function shortCategory(category, displayCategoryId) {
    if (displayCategoryId) {
        return getCategoryTitle(displayCategoryId)
    }

    if (!category) {
        return ''
    }

    const extra = category.indexOf(' (')
    if (extra !== -1) {
        return category.substr(0, extra)
    }
    return category
}

export function carrierDivStyle(logos, name) {
    if (!logos) {
        return {}
    }

    const { style: divStyle = {} } = logoStyle(logos, name)
    const bgEntry = logos[name + 'Bg']
    const borderTopColor = bgEntry ? bgEntry : logos[name + 'BgAlt']
    if (borderTopColor) divStyle['borderTopColor'] = borderTopColor
    return divStyle
}
