//
import React from 'react'
import cx from 'classnames'

/**
 * DropdownLabel Properties
 */

/* $FlowFixMe */
const DropdownLabel = React.memo(({ title, onSelect, value, extraClass }) => (
    <li className={cx('srch-choice', extraClass)}>
        <span dangerouslySetInnerHTML={{ __html: title }} />{' '}
        {onSelect && (
            <i
                className="far fa-times"
                onClick={() => {
                    onSelect(value)
                }}
            />
        )}
    </li>
))

export default DropdownLabel
