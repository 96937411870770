// assets
import IconBackup from 'assets/images/ratesmgr/features/icon-duo-feat-backup-carrier.png'
import IconDimentional from 'assets/images/ratesmgr/features/icon-duo-feat-dimensional.png'
import IconDropship from 'assets/images/ratesmgr/features/icon-duo-feat-multiorigin.png'
import IconOrderView from 'assets/images/ratesmgr/features/icon-duo-feat-shipping-insights.png'
import IconFrieght from 'assets/images/ratesmgr/features/icon-duo-feat-ltl-freight.png'
import IconRateshop from 'assets/images/ratesmgr/features/icon-duo-feat-rate-shopping.png'
import IconDatetime from 'assets/images/ratesmgr/features/icon-duo-feat-date-time.png'
import IconResidential from 'assets/images/ratesmgr/features/icon-duo-feat-address-type.png'
import IconPickup from 'assets/images/ratesmgr/features/icon-duo-feat-instore.png'
import IconAddressValid from 'assets/images/ratesmgr/features/icon-duo-feat-address-validation.png'
import IconUnlimited from 'assets/images/ratesmgr/features/icon-duo-feat-rules.png'
import IconCrossborder from 'assets/images/ratesmgr/features/icon-duo-feat-landed-cost.png'
import IconPpse from 'assets/images/ratesmgr/features/icon-duo-feat-shipping-estimates.png'
import IconEc from 'assets/images/ratesmgr/features/icon-duo-feat-ec.png'
import IconIncentives from 'assets/images/ratesmgr/features/icon-shipping-incentives.svg'
import IconAuditLog from 'assets/images/ratesmgr/features/icon-audit-log.svg'

const iconsMap = {
    backupcarrier: IconBackup,
    dimship: IconDimentional,
    dropship: IconDropship,
    ltl_freight: IconFrieght,
    method_mapping: IconRateshop,
    shipcal: IconDatetime,
    residential: IconResidential,
    storepickup: IconPickup,
    order_view: IconOrderView,
    validation: IconAddressValid,
    unlimited_rules: IconUnlimited,
    cross_border: IconCrossborder,
    label: IconOrderView,
    ppse: IconPpse,
    ec: IconEc,
    incentives: IconIncentives,
    audit_log: IconAuditLog,
}

export default function iconMapper(id) {
    const icon = iconsMap[id]
    return icon ? icon : ''
}

export {
    IconBackup,
    IconDimentional,
    IconDropship,
    IconOrderView,
    IconFrieght,
    IconRateshop,
    IconDatetime,
    IconResidential,
    IconPickup,
    IconAddressValid,
    IconUnlimited,
    IconCrossborder,
    IconEc,
}
