import React, { Component, Fragment } from 'react'
import cx from 'classnames'
import Modal from 'components/ui/Modal'
import ModalMultiselectGroup from 'components/ui/ModalMultiselectGroup'
import ModalMultiselectSelectedOption from 'components/ui/ModalMultiselectSelectedOption'
import ScrollableList from 'components/ui/ScrollableList'
import { filterOptions } from './filterOptions'

export default class ModalMultiselect extends Component {
    wrapperRef
    listRef
    modalListRef

    static defaultProps = {
        disabled: false,
        entityName: 'Methods',
        onChange: () => undefined,
    }

    constructor(props) {
        super(props)

        this.wrapperRef = React.createRef()
        this.listRef = React.createRef()
        this.modalListRef = React.createRef()
        this.state = {
            opened: false,
            selected: props.selected,
            modalVisible: false,
            addList: {},
            searchValue: '',
            options: filterOptions('', this.props.options),
            selectAll: false,
        }

        this.onCreateNew = this.onCreateNew.bind(this)
        this.clearAll = this.clearAll.bind(this)
        this.onGroupSelected = this.onGroupSelected.bind(this)
        this.saveSelection = this.saveSelection.bind(this)
        this.selectAll = this.selectAll.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        // userInput is present in the state when user changed the selected value
        if (state.userInput) {
            return {
                ...state,
                selected: state.selected,
            }
        } else {
            return {
                ...state,
                selected: props.selected,
            }
        }
    }

    hide = () => {
        this.setState({
            modalVisible: false,
        })
    }

    onCreateNew() {
        const { onCreateNew } = this.props

        this.setState({
            opened: false,
        })

        if (onCreateNew) {
            onCreateNew()
        }
    }

    select = (value) => {
        const { selected } = this.state
        let result = selected
            ? Array.from(selected).filter((item) => item !== value)
            : []

        if (!selected || !selected.includes(value)) {
            result.push(value)
        }

        let newState = {
            selected: result,
            userInput: true,
        }

        this.setState(newState)

        if (this.props.onChange) {
            this.props.onChange(result.length > 0 ? result : [''])
        }
    }

    handleClickOutside = (event) => {
        if (
            this.wrapperRef &&
            this.wrapperRef.current &&
            !this.wrapperRef.current.contains(event.target)
        ) {
            this.hide()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.opened && !this.state.opened) {
            document.removeEventListener('mousedown', this.handleClickOutside)
        } else if (!prevState.opened && this.state.opened) {
            document.addEventListener('mousedown', this.handleClickOutside)
        }
    }

    getValueCmp(values) {
        return (option) => values && values.includes(option.value)
    }

    clearAll(e) {
        e.preventDefault()

        this.setState({
            selected: [],
        })

        if (this.props.onChange) {
            this.props.onChange([''])
        }
    }

    renderSelection() {
        const { options, entityName } = this.props
        const { selected } = this.state
        const result = {}

        if (
            !selected ||
            !selected.length ||
            (selected.length === 1 && selected[0] === '')
        ) {
            return (
                <input
                    className="form-control"
                    disabled=""
                    placeholder={`No ${entityName} Selected`}
                    onClick={() =>
                        this.setState({
                            searchValue: '',
                            modalVisible: true,
                            selectAll: false,
                            options: filterOptions('', this.props.options),
                            /* $FlowFixMe */
                            addList: Array.isArray(this.state.selected)
                                ? this.state.selected.reduce((acc, value) => {
                                      acc[value] = true
                                      return acc
                                  }, {})
                                : {},
                        })
                    }
                />
            )
        }

        for (const group of options) {
            /* $FlowFixMe */
            if (group.options) {
                /* $FlowFixMe */
                for (const option of group.options) {
                    if (!selected.includes(option.value)) {
                        continue
                    }
                    if (!result[group.title]) {
                        result[group.title] = []
                    }

                    result[group.title].push(option)
                }
            } else {
                if (!selected.includes(group.value)) {
                    continue
                }

                if (!result[0]) {
                    result[0] = []
                }
                result[0].push(group)
            }
        }

        return (
            <div className="results">
                <div className="r-hd">
                    <span>Selected {entityName}</span>
                    <a onClick={this.clearAll}>clear all</a>
                </div>
                <ScrollableList>
                    {Object.entries(result).map(([title, group]) => (
                        <Fragment key={title}>
                            {title !== '0' ? (
                                <div
                                    className="r-sb-hd"
                                    dangerouslySetInnerHTML={{ __html: title }}
                                />
                            ) : null}
                            {/* $FlowFixMe */}
                            {group.map((option) => (
                                <ModalMultiselectSelectedOption
                                    key={option.value}
                                    {...option}
                                    onRemove={this.select}
                                    isChild={title !== '0'}
                                />
                            ))}
                        </Fragment>
                    ))}
                </ScrollableList>
            </div>
        )
    }

    onGroupSelected(value, checked, multiSelect) {
        this.setState((state) => {
            const result = {
                selectAll: false,
                addList: {
                    ...state.addList,
                },
                lastSelection: checked ? value : null,
            }
            const { lastSelection } = state
            const { options } = this.props

            let targetState = checked
            let valueList = [value]

            if (multiSelect && lastSelection) {
                const allOptionValues = []
                for (const group of options) {
                    /* $FlowFixMe */
                    if (group.options) {
                        /* $FlowFixMe */
                        for (const option of group.options) {
                            allOptionValues.push(option.value)
                        }
                    } else {
                        allOptionValues.push(group.value)
                    }
                }

                const idx1 = allOptionValues.indexOf(lastSelection),
                    idx2 = allOptionValues.indexOf(value)
                valueList = allOptionValues.slice(
                    Math.min(idx1, idx2),
                    Math.max(idx1, idx2) + 1
                )
            }

            // if all options are checked - uncheck
            if (targetState && valueList.length !== -1) {
                let allSet = true
                for (const id of valueList) {
                    if (!result.addList[id]) {
                        allSet = false
                    }
                }

                targetState = !allSet
            }

            for (const id of valueList) {
                if (targetState) {
                    result.addList[id] = true
                } else {
                    delete result.addList[id]
                }
            }

            if (options.length === Object.values(result.addList).length) {
                result.selectAll = true
            }

            return result
        })
    }

    renderOptions() {
        const { options } = this.state

        return (
            options &&
            options.map((option, idx) => (
                <ModalMultiselectGroup
                    key={idx}
                    title={option.title}
                    value={option.value}
                    options={option.options}
                    addList={this.state.addList}
                    onSelect={this.onGroupSelected}
                />
            ))
        )
    }

    saveSelection() {
        const { addList } = this.state

        const selected = Object.keys(addList)
            .map((item) => parseInt(item))
            .filter((value, index, self) => {
                // filter unique values
                return self.indexOf(value) === index
            })

        let newState = {
            selected,
            userInput: true,
            addList: [],
        }

        this.setState(newState)
        if (this.props.onChange) {
            this.props.onChange(selected.length > 0 ? selected : [''])
        }

        this.hide()
    }

    selectAll(e) {
        const { options, selectAll } = this.state
        const count = Object.keys(this.state.addList).length
        const allCount = this.props.options.length
        const isSelectedAll = selectAll || allCount === count

        let newAddList = { ...this.state.addList }

        const newValue = e ? e.currentTarget.checked : !isSelectedAll

        for (const group of options) {
            /* $FlowFixMe */
            if (group.options) {
                /* $FlowFixMe */
                for (const option of group.options) {
                    newAddList[option.value] = newValue
                }
            } else {
                newAddList[group.value] = newValue
            }
        }

        newAddList = Object.keys(newAddList).reduce(
            (result, optionId) =>
                newAddList[optionId]
                    ? { ...result, [optionId]: newAddList[optionId] }
                    : { ...result },
            {}
        )

        this.setState({
            selectAll: newValue,
            addList: newAddList,
        })
    }

    renderSelectAll() {
        const { selectAll, addList } = this.state
        const count = Object.keys(addList).length
        const allCount = this.props.options.length
        const isSelectedAll = selectAll || allCount === count

        return (
            <div className="slct-all opt" onClick={(e) => this.selectAll(null)}>
                <input
                    type="checkbox"
                    checked={isSelectedAll}
                    onChange={this.selectAll}
                />{' '}
                <span>Select All</span>
                <>{count || 0} selected</>
            </div>
        )
    }

    renderSearch() {
        return (
            <div className="srch">
                <i className="far fa-search" />
                <input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                        const searchValue = e.target.value

                        this.setState({
                            searchValue,
                            options: filterOptions(
                                searchValue,
                                this.props.options
                            ),
                        })
                    }}
                />
            </div>
        )
    }

    renderMissingDataMessage(hasOptions) {
        if (hasOptions) return null

        const { searchValue } = this.state
        const { missingDataNote } = this.props

        const message = searchValue
            ? `There are no methods that match "${searchValue}"`
            : missingDataNote
            ? missingDataNote
            : `There are no methods that match`

        return <p className="text-center mt30">{message}</p>
    }

    render() {
        const { title, hint, required, meta, entityName } = this.props
        const { modalVisible, options } = this.state
        const hasError = meta
            ? (meta.error || meta.submitError) && meta.touched
            : false

        const hasOptions = options && options.length > 0

        return (
            <div className="md-ms">
                <div
                    className={cx('form-group', {
                        required,
                        'has-error': hasError,
                    })}
                >
                    <label className="control-label">{title}</label>
                    {this.renderSelection()}
                    <p className="help-block">{hint}</p>
                    {hasError && (
                        <span className="help-block">
                            <i className="fa fa-exclamation-circle" />{' '}
                            {meta.error ? meta.error : meta.submitError}
                        </span>
                    )}
                </div>
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                        this.setState({
                            searchValue: '',
                            modalVisible: true,
                            selectAll: false,
                            options: filterOptions('', this.props.options),
                            /* $FlowFixMe */
                            addList: Array.isArray(this.state.selected)
                                ? this.state.selected.reduce((acc, value) => {
                                      acc[value] = true
                                      return acc
                                  }, {})
                                : {},
                        })
                    }
                >
                    Add {entityName}
                </button>

                {modalVisible && (
                    <Modal
                        contentWrapClass="mbasic mlist mlist-modal"
                        onClose={this.hide}
                    >
                        <div className="mlist-header">
                            {title && (
                                <div className="mlist-title">{title}</div>
                            )}
                            {this.renderSearch()}
                        </div>

                        <ScrollableList maxHeight={360}>
                            {hasOptions ? this.renderSelectAll() : null}
                            {this.renderOptions()}
                            {this.renderMissingDataMessage(hasOptions)}
                        </ScrollableList>

                        <div className="actions">
                            <button
                                className="btn btn-bl-o"
                                type="button"
                                onClick={this.hide}
                            >
                                Cancel
                            </button>

                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={this.saveSelection}
                                disabled={!hasOptions}
                            >
                                Apply
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}
