//
import * as React from 'react'
import { Component } from 'react'
import DropdownContext from 'components/ui/DropdownContext'
import cx from 'classnames'

export default class DropdownItem extends Component {
    onSelect = (e, context) => {
        e.preventDefault()

        if (context) {
            context.close()
        }

        if (this.props.onSelect) {
            this.props.onSelect(this.props.value)
        }
    }

    render() {
        const { title, selected, delimiter, hovered, linkClass } = this.props
        let icon = null

        if (delimiter) {
            return (
                <li>
                    <div className="div-contain">
                        <div className="divider" />
                    </div>
                </li>
            )
        }

        if (selected) {
            icon = <i className="far fa-check" />
        }

        return (
            <DropdownContext.Consumer>
                {(context) => (
                    <li
                        data-testid={title}
                        className={cx({
                            selected,
                        })}
                        role="option"
                        aria-checked={selected}
                        aria-selected={selected}
                    >
                        <a
                            className={cx(
                                {
                                    hover: hovered,
                                },
                                linkClass
                            )}
                            href="#"
                            role="button"
                            onMouseDown={(e) => this.onSelect(e, context)}
                        >
                            <span className="text">{title}</span>
                            {icon}
                        </a>
                    </li>
                )}
            </DropdownContext.Consumer>
        )
    }
}
