import React from 'react'
import styled from 'styled-components'

import { radioUnselectedUrl, radioSelectedUrl } from './RadioIcons'

export const RADIO_WIDTH = 16
export const RADIO_MARGIN_LEFT = 8
export const RADIO_OFFSET = RADIO_WIDTH + RADIO_MARGIN_LEFT

const RadioLabel = styled.label`
    cursor: pointer;
    display: flex !important;
    align-items: flex-start;
    margin-bottom: 8px;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
`

const RadioCheckbox = styled.div`
    cursor: pointer;
    width: ${RADIO_WIDTH}px;
    min-width: ${RADIO_WIDTH}px;
    height: 15px;
    margin-right: ${RADIO_MARGIN_LEFT}px;
    margin-top: 2px;
    background-image: url('${radioUnselectedUrl}');
`

const RadioInput = styled.input`
    display: none;
    visibility: hidden;

    &:checked + div {
        background-image: url('${radioSelectedUrl}');
    }
`

const RadioHint = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey5};
    margin-bottom: 10px;
    margin-top: 4px;
`

interface RadioInputProps {
    labelFor: string
    className?: string
    disabled?: boolean
    required?: boolean
    checked: boolean
    title: JSX.Element | string
    hint?: JSX.Element | string
    onChange?: any
}

const RadioInputComponent: React.FC<RadioInputProps> = ({
    labelFor,
    className,
    disabled,
    required,
    checked,
    title,
    hint,
    ...input
}) => {
    const labelClassName =
        (className ? className : 'control-label optional') +
        (checked ? ' active' : '')

    return (
        <>
            <RadioLabel htmlFor={labelFor} className={labelClassName}>
                <RadioInput
                    disabled={disabled}
                    {...input}
                    id={labelFor}
                    type="radio"
                    className="optional"
                    {...(required ? { 'aria-required': 'true' } : null)}
                    checked={checked}
                />

                <RadioCheckbox />

                <div>
                    {title}

                    {hint && <RadioHint>{hint}</RadioHint>}
                </div>
            </RadioLabel>
        </>
    )
}

export default RadioInputComponent
