//
import React from 'react'

/**
 * ModalMultiselectGroup Properties
 */

const ModalMultiselectGroup = ({
    title,
    value,
    options,
    onSelect,
    addList,
}) => {
    return options ? (
        <div>
            <h5
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
            />
            {options.map((subOption) => (
                <div
                    className="opt child"
                    key={subOption.value}
                    onMouseDown={(e) => {
                        if (e.shiftKey) {
                            clearSelection()
                            onSelect(subOption.value, true, true)
                        } else {
                            onSelect(subOption.value, !addList[subOption.value])
                        }
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={() => false}
                        checked={!!addList[subOption.value]}
                        data-testid={subOption.value}
                        data-testlabel={subOption.title}
                    />{' '}
                    <div>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: subOption.title,
                            }}
                        />
                    </div>
                </div>
            ))}
        </div>
    ) : (
        <div
            className="opt"
            key={value}
            onMouseDown={(e) => {
                if (e.shiftKey) {
                    clearSelection()
                    onSelect(value, true, true)
                } else {
                    onSelect(value, !addList[value])
                }
            }}
        >
            <input
                type="checkbox"
                onChange={() => false}
                checked={!!addList[value]}
                data-testid={value}
            />{' '}
            <div>
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </div>
        </div>
    )
}

function clearSelection() {
    if (window.getSelection) {
        if (window.getSelection().empty) {
            // Chrome
            window.getSelection().empty()
        } else if (window.getSelection().removeAllRanges) {
            // Firefox
            window.getSelection().removeAllRanges()
        }
        /* $FlowFixMe */
    } else if (document.selection) {
        // IE?
        document.selection.empty()
    }
}

export default ModalMultiselectGroup
