import { useQuery } from 'react-query'
import { axiosQuery as axiosClient } from 'net/ajax'

const ENTERPRISE_PLAN_BILLING: PlanBillingType = {
    id: 'enterprise',
    display_name: 'Enterprise',
    name: 'enterprise',
    cost: 'none',
    price: 'none',
    currency_code: 'none',
    billing_frequency: 'none',
    description:
        'Let us be your shipping experts. Get access to all ShipperHQ carriers and features, plus dedicated support to help tackle challenging shipping requirements.',
    limits: [],
    services: [
        'unlimited_features_access',
        'dedicated_account_manager',
        'configuration_service',
        'strategic_consulting',
        'dedicated_support',
    ],
    available_features: [],
    not_available_features: [],
}

const getPlanBilling = ({ name }: { name: string }) => {
    return axiosClient
        .get(`/ratesmgr/billing/show_plan_details?plan_name=${name}`)
        .then(({ data }) => {
            return data as PlanBillingType
        })
}

export const usePlanBilling = ({ name }: { name: string }) => {
    const fetchPlanBilling = () => {
        if (!name) return {}

        return name === 'enterprise'
            ? ENTERPRISE_PLAN_BILLING
            : getPlanBilling({ name })
    }

    const { data, ...rest } = useQuery(['plan_billing', name], fetchPlanBilling)

    const planBilling = data as PlanBillingType

    return { planBilling, ...rest }
}
