//
import React, { Fragment } from 'react'

/**
 * ModalMultiselectSelectedOption Properties
 */

const ModalMultiselectSelectedOption = ({
    title,
    value,
    onRemove,
    isChild,
}) => {
    return (
        <div className={'r-rw' + (isChild ? ' child' : '')}>
            <span>{title}</span>
            <a
                className="fal fa-times"
                onClick={() => {
                    onRemove(value)
                }}
            />
        </div>
    )
}

export default ModalMultiselectSelectedOption
