import React, { useMemo, useReducer } from 'react'
import {
    ImmerReducer,
    createActionCreators,
    createReducerFunction,
} from 'immer-reducer'

type WizardState = {
    steps: Array<string>
    currentState: string
    currentStep: string
    context: Object
    isDone: boolean
}

interface WizardContextProps {
    state: WizardState
    onCompleted: Function
    dispatch: React.Dispatch<any>
}

interface WizardProviderProps {
    context: any
    defaultContext?: any
    onCompleted: Function
    children: JSX.Element
    initialStep?: string
    steps: Array<any>
}

const buildInitialState = (
    steps: Array<string>,
    defaultContext: any = {},
    initialStep?: string
): WizardState => ({
    steps: steps,
    currentState: initialStep || steps[0],
    currentStep: initialStep || steps[0],
    context: defaultContext,
    isDone: false,
})

class WizardImmerReducer extends ImmerReducer<WizardState> {
    setCurrentStep(step) {
        this._validateStep(step)

        this.draftState.currentStep = step
    }

    setCurrentState(state) {
        this._validateStep(state)

        this.draftState.currentState = state
    }

    setContext(contextSetter) {
        const newContext =
            typeof contextSetter === 'function'
                ? contextSetter(this.draftState.context)
                : contextSetter

        this.draftState.context = newContext
    }

    submitStep(newContext) {
        const currentStep = this.draftState.currentState
        const currentStateIndex = this.draftState.steps.indexOf(currentStep)
        const nextStep = this.draftState.steps[currentStateIndex + 1]

        if (nextStep) {
            this.setCurrentStep(nextStep)
            this.setCurrentState(nextStep)
        }

        if (newContext) {
            this.draftState.context = newContext
        }
    }

    skipStep(step, newContext) {
        this._validateStep(step)

        const currentStateIndex = this.draftState.steps.indexOf(step)
        const nextStep = this.draftState.steps[currentStateIndex + 1]

        if (nextStep) {
            this.setCurrentStep(nextStep)
            this.setCurrentState(nextStep)
        }

        if (newContext) {
            this.draftState.context = newContext
        }
    }

    resetState(state) {
        this._validateStep(state)

        this.draftState.currentState = state
        this.draftState.currentStep = state
    }

    reset() {
        this.draftState = buildInitialState(this.draftState.steps)
    }

    setSteps(steps) {
        const prevSteps = [...this.draftState.steps]
        const stepIndex = prevSteps.indexOf(this.draftState.currentStep)
        const stateIndex = prevSteps.indexOf(this.draftState.currentState)

        if (!steps.includes(this.draftState.currentState)) {
            this.draftState.currentState = prevSteps[stateIndex - 1]
        }

        if (!steps.includes(this.draftState.currentStep)) {
            this.draftState.currentStep = prevSteps[stepIndex - 1]
        }

        this.draftState.steps = steps
    }

    _validateStep(step) {
        if (!this.draftState.steps.includes(step))
            throw `Unsupported step ${step}`
    }
}

const reducerAction = createActionCreators(WizardImmerReducer)
const wizardReducer = createReducerFunction(WizardImmerReducer)

export const WizardStateProvider = ({
    context: Context,
    defaultContext,
    onCompleted,
    children,
    initialStep,
    steps: defaultSteps,
}: WizardProviderProps) => {
    const [state, dispatch] = useReducer(
        wizardReducer,
        useMemo(
            () => buildInitialState(defaultSteps, defaultContext, initialStep),
            [defaultSteps, defaultContext]
        )
    )

    const value: WizardContextProps = {
        state,
        dispatch,
        onCompleted,
    }

    return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useWizardState = ({
    context: Context,
}: {
    context: WizardContextProps
}) => {
    const { state, onCompleted, dispatch } = React.useContext(Context)
    const { steps, currentState, currentStep, context, isDone } = state

    const prevStep = steps[steps.indexOf(currentStep) - 1]
    const nextStep = steps[steps.indexOf(currentStep) + 1]

    const setSteps = (steps) => {
        dispatch(reducerAction.setSteps(steps))
    }

    const setCurrentStep = (step) => {
        dispatch(reducerAction.setCurrentStep(step))
    }

    const setCurrentState = (state) => {
        dispatch(reducerAction.setCurrentState(state))
    }

    const setContext = (context) => {
        dispatch(reducerAction.setContext(context))
    }

    const addToContext = (context) => {
        dispatch(reducerAction.setContext((prev) => ({ ...prev, ...context })))
    }

    const resetState = (state) => {
        dispatch(reducerAction.resetState(state))
    }

    const reset = () => {
        dispatch(reducerAction.reset())
    }

    const submitStep = (newContext) => {
        if (nextStep) {
            dispatch(reducerAction.submitStep(newContext))
        } else {
            onCompleted(newContext || context)
        }
    }

    const skipStep = (step, newContext = null) => {
        if (!steps.includes(step)) throw `Unsupported step ${step}`
        const newStep = steps[steps.indexOf(step) + 1]

        if (newStep) {
            dispatch(reducerAction.skipStep(step, newContext))
        } else {
            onCompleted(newContext || context)
        }
    }

    const skipCurrentStep = (newContext = null) => {
        skipStep(currentStep, newContext)
    }

    const stepIsAvailable = (step) => {
        const stepIndex = steps.indexOf(step)
        const currentStateIndex = steps.indexOf(currentState)
        const avaiableSteps = [
            ...steps.slice(0, currentStateIndex),
            currentState,
        ]

        return stepIndex === 0 || avaiableSteps.includes(step)
    }

    const handleSubmitStep = (newContext = null) => {
        if (currentState !== currentStep) {
            handleNextStep()
        } else {
            submitStep(newContext)
        }
    }

    const handlePrevStep = stepIsAvailable(prevStep)
        ? () => setCurrentStep(prevStep)
        : null

    const handleNextStep = stepIsAvailable(nextStep)
        ? () => setCurrentStep(nextStep)
        : null

    const pagination = {
        currentStep: steps.indexOf(currentStep) + 1,
        totalCount: steps.length,
    }

    return {
        state,
        steps,
        dispatch,
        resetState,
        reset,
        setCurrentState,
        setCurrentStep,
        prevStep: handlePrevStep,
        nextStep: handleNextStep,
        skipStep,
        skipCurrentStep,
        setContext,
        addToContext,
        context,
        submitStep: handleSubmitStep,
        setStep: setCurrentStep,
        currentStep,
        currentState,
        isDone,
        pagination,
        setSteps,
    }
}
