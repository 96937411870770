import React from 'react'
import Modal from 'components/ui/Modal'
import { useSelector } from 'react-redux'
import useRailsRoutes from 'hooks/useRailsRoutes'
import { usePlanBilling } from 'hooks/usePlanBilling'

import PlanCard from './PlanCard'

export const COMPARE_PLAN_MODAL_ID = 'compare-plans'

interface ComparePlanModalProps {
    currentPlan: string
    upgradePlan: string
    open: boolean
    onClose?: React.MouseEventHandler
    onUpgrade?: (upgradePlanBilling: PlanBillingType) => void
}

const ComparePlanModal: React.FC<ComparePlanModalProps> = ({
    currentPlan,
    upgradePlan,
    open,
    onClose,
    onUpgrade,
}) => {
    const routes = useRailsRoutes()

    const { planBilling: currentPlanBilling } = usePlanBilling({
        name: currentPlan,
    })

    const { planBilling: upgradePlanBilling } = usePlanBilling({
        name: upgradePlan,
    })

    const { currentUser = {} } = useSelector(
        ({ app: { current_user } }: any) => {
            return {
                currentUser: current_user,
            }
        }
    )

    const upgradeLinkPath = routes.edit_ratesmgr_profile_path(currentUser.id, {
        section: 'pricing_plans',
    })

    if (!open || !currentPlanBilling?.id || !upgradePlanBilling?.id) return null

    const handleUpgrade = (event) => {
        event.preventDefault()
        event.stopPropagation()
        onUpgrade(upgradePlanBilling)
    }

    return (
        <Modal
            title={<strong>Upgrade to activate this feature</strong>}
            onClose={onClose}
            modalName={COMPARE_PLAN_MODAL_ID}
        >
            <div className="plan-compare-cards">
                <PlanCard planBilling={currentPlanBilling} />
                <PlanCard planBilling={upgradePlanBilling} upgrade />
            </div>

            <div className="plan-compare-actions">
                {upgradePlanBilling.name === 'enterprise' ? (
                    <a
                        className="btn btn-primary"
                        href="/enterprise-contact"
                        target="_blank"
                    >
                        Speak to an Expert
                    </a>
                ) : (
                    <button
                        data-testid="confirmUpgradePlan"
                        className="btn btn-primary"
                        onClick={handleUpgrade}
                    >
                        Upgrade Plan
                    </button>
                )}

                <a className="primary-link-lg" href={upgradeLinkPath}>
                    See all plans
                </a>
            </div>
        </Modal>
    )
}

export default ComparePlanModal
