//
export const isPlatform = (currentPlatform, platform) => {
    if (!currentPlatform) {
        return false
    }

    const platformLower = currentPlatform.toLocaleLowerCase().replace('.', '')

    if (Array.isArray(platform)) {
        return platform.includes(platformLower)
    }

    return platform === platformLower
}

export const isMagento = (currentPlatform) => {
    if (!currentPlatform) {
        return false
    }

    const platformLower = currentPlatform.toLocaleLowerCase().replace('.', '')

    if (
        platformLower !== 'bigcommerce' &&
        platformLower !== 'woocommerce' &&
        platformLower !== 'shopify' &&
        platformLower !== 'salesforce' &&
        platformLower !== 'commercetools' &&
        platformLower !== 'cartcom'
    ) {
        return true
    }

    return false
}

export const isShopify = (currentPlatform) => {
    if (!currentPlatform) {
        return false
    }

    let platformLower = currentPlatform.toLocaleLowerCase().replace('.', '')

    if (platformLower === 'shopify') {
        return true
    }

    return false
}
