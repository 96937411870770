import { createContext } from 'react'

const EntityEditorContext = createContext({
    isEmbedded: false,
    onCreate: (entityId) => {},

    // used by form components to hold state
    getCustomData: (property) => {},
    setCustomData: (property, value) => {},
})

export default EntityEditorContext
