//

export const categories = [
    {
        title: 'Small Package Carriers',
        id: 'small_package',
        parent: 'all_carriers',
    },
    { title: 'LTL Freight Carriers', id: 'freight', parent: 'all_carriers' },
    {
        title: 'Cross Border Carriers',
        id: 'cross_border',
        parent: 'all_carriers',
    },
    { title: 'Same Day Carriers', id: 'same_day', parent: 'all_carriers' },
    { title: 'White Glove Service', id: 'white_glove', parent: 'all_carriers' },
    {
        title: 'In-home Delivery',
        id: 'in_home_delivery',
        parent: 'all_carriers',
    },
    { title: 'Address Validation', id: 'validation' },
    { title: 'Shipping Insurance', id: 'insurance' },
    { title: 'Technology Partner', id: 'tech_partner' },
]

export const carrierCategories = [
    'small_package',
    'freight',
    'cross_border',
    'same_day',
    'white_glove',
    'in_home_delivery',
]

export function getCategoryTitle(id) {
    if (id === 'all_carriers') {
        return 'All Carriers'
    }

    const category = categories.find((item) => item.id === id)
    return category ? category.title : 'All'
}

export function categoryChildren(id) {
    if (!id) {
        return undefined
    }

    if (id === 'all') {
        return categories
    }

    return categories.filter((item) =>
        item.parent ? item.parent === id : false
    )
}

export function categoryMatches(
    carrier,
    queryCategory,
    secondaryCategories = {}
) {
    if (
        carrier.category_id !== queryCategory &&
        !(
            queryCategory === 'all_carriers' &&
            carrierCategories.includes(carrier.category_id)
        )
    ) {
        const mapEntry = secondaryCategories[carrier.name]
        if (mapEntry) {
            if (!mapEntry.includes(queryCategory)) {
                return false
            }
        } else {
            return false
        }
    }

    return true
}
