import React from 'react'
import classnames from 'classnames'

interface PlanCardProps {
    upgrade?: boolean
    planBilling: PlanBillingType
}

const PLAN_SERVICES_TITLES = {
    basic_support: 'Basic Support',
    phone_support: 'Basic + Phone Support',
    priority_support: 'Priority support',
    configuration_assistance: 'Configuration assistance',
    unlimited_features_access: 'Custom feature limit',
    dedicated_account_manager: 'Dedicated support',
    configuration_service: 'Account setup and configuration services',
    strategic_consulting: 'Access to our Customer Success Team',
}

const PLAN_SERVICES_ICONS = {
    basic_support: 'fa-comment-alt',
    phone_support: 'fa-comment-alt-plus',
    priority_support: 'fa-user-headset',
    configuration_assistance: 'fa-tools',
    unlimited_features_access: 'fa-infinity',
    dedicated_account_manager: 'fa-user-check',
    configuration_service: 'fa-tools',
    strategic_consulting: 'fa-bullseye-arrow',
}

const PlanCard: React.FC<PlanCardProps> = ({ planBilling, upgrade }) => {
    const isEnterprise = planBilling.name === 'enterprise'

    return (
        <div className={classnames('plan-card', { enterprise: isEnterprise })}>
            <div className={classnames('plan-card__nav-header', { upgrade })}>
                {upgrade ? 'Upgrade to:' : 'Current plan:'}
            </div>

            <div className="plan-card__content">
                <div className="plan-card__header">
                    <div className="plan-card__title">
                        <h3>
                            <strong>{planBilling.display_name}</strong>
                        </h3>
                    </div>

                    {isEnterprise ? (
                        <>
                            <div className="plan-card__contact_us">
                                Contact us for pricing
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="plan-card__price">{`${planBilling.currency_code}${planBilling.price}/mo`}</div>
                            {parseInt(planBilling.price) !== 0 && (
                                <div className="plan-card__note">{`billed ${planBilling.billing_frequency}`}</div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {planBilling.description && (
                <div className="plan-card__description">
                    {planBilling.description}
                </div>
            )}

            <ul className="plan-card__limits">
                {planBilling.limits.map((limit, index) => (
                    <li key={`limit-${index}`}>
                        <strong>{limit.max_allowed}</strong>&nbsp;
                        {limit.title}
                        {limit.limits_exceeded && (
                            <i
                                data-testid={`limits-${planBilling.name}-${limit.id}`}
                                className="fas fa-triangle-exclamation danger ml-1"
                            />
                        )}
                    </li>
                ))}
            </ul>

            <ul className="plan-card__services">
                {planBilling.services.map((service, index) => (
                    <li key={`feature-${index}`}>
                        <i className={`fas ${PLAN_SERVICES_ICONS[service]}`} />
                        {PLAN_SERVICES_TITLES[service]}
                    </li>
                ))}

                {isEnterprise ? (
                    <></>
                ) : planBilling.not_available_features.length > 0 ? (
                    <li>
                        <i className="fas fa-lock" />
                        Restricted Feature Access
                    </li>
                ) : (
                    <li>
                        <i className="fas fa-unlock" />
                        Access to All Features
                    </li>
                )}

                {planBilling.not_available_features.map((feature) => (
                    <li className="disabled" key={`notAvailable-${feature}`}>
                        <i className="far fa-times" />
                        {feature}
                    </li>
                ))}

                {planBilling.available_features.map((feature) => (
                    <li key={`available-${feature}`}>
                        <i className="far fa-check" />
                        {feature}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default PlanCard
