export const hasFeature = (featuresEnabled, featureList) => {
    if (!featuresEnabled) {
        return false
    }

    if (Array.isArray(featureList)) {
        for (const feature of featureList) {
            if (!featuresEnabled.includes(feature)) {
                return false
            }
        }

        return true
    } else {
        return featuresEnabled.includes(featureList)
    }
}

export const hasAnyFeature = (featuresEnabled, featureList) => {
    if (!featuresEnabled) {
        return false
    }

    for (const feature of featureList) {
        if (featuresEnabled.includes(feature)) {
            return true
        }
    }

    return false
}

export const hasPreviewFeature = (previewFeature) => {
    return window?.appData?.current_user?.preview_features?.includes(
        previewFeature
    )
}
