import { compareField } from 'utils/fields'
import { isMagento, isPlatform } from 'utils/platform'
import { hasFeature, hasAnyFeature, hasPreviewFeature } from 'utils/features'
import { isCrossBorderProvider } from 'utils/crossBorder'
import { AVAILABLE_PLATFORM_PLANS } from 'components/dashboard/features/useFeatures'

/**
 * This is the object defined in YML
 */

export default function checkFieldRequirements(
    requirements,
    options,
    values = {}
) {
    const {
        enabledFeatures,
        platform,
        plan,
        userSettings,
        renderedFields,
        isDefaultValue,
        carriersLimit,
        carriersCount,
        originsLimit,
        originsCount,
        quickRulesLimit,
        quickRulesCount,
        crossBorderSettings,
    } = options
    let result = true

    for (const requirement of requirements) {
        if (requirement.field) {
            const fieldDef = requirement.field
            if (!fieldDef.name) {
                console.error(
                    'fieldDef.name is missing!',
                    JSON.stringify(requirement)
                )
                continue
            }

            if (
                !compareField(
                    values[fieldDef.name],
                    fieldDef.value,
                    fieldDef.op
                )
            ) {
                return false
            }
        } else if (requirement.platform) {
            if (!isPlatform(platform, requirement.platform)) {
                return false
            }
        } else if (requirement.platform_neq) {
            if (isPlatform(platform, requirement.platform_neq)) {
                return false
            }
        } else if (requirement.all_plans) {
            return true
        } else if (requirement.plan) {
            if (!isPlan(plan, requirement.plan)) {
                return false
            }
        } else if (requirement.upsell_plan) {
            return isUpsellPlan({
                plan,
                platform,
                upsellPlans: requirement.upsell_plan,
            })
        } else if (requirement.plan_neq) {
            if (isPlan(plan, requirement.plan_neq)) {
                return false
            }
        } else if (requirement.magento_platform !== undefined) {
            if (!requirement.magento_platform || !isMagento(platform)) {
                return false
            }
        } else if (requirement.feature) {
            if (!hasFeature(enabledFeatures, requirement.feature)) {
                return false
            }
        } else if (requirement.preview_feature) {
            if (!hasPreviewFeature(requirement.preview_feature)) {
                return false
            }
        } else if (requirement.any_feature) {
            if (!hasAnyFeature(enabledFeatures, requirement.any_feature)) {
                return false
            }
        } else if (requirement.user_settings_any_set) {
            if (!userSettings) {
                return false
            }

            for (const setting of requirement.user_settings_any_set) {
                if (userSettings[setting]) {
                    return true
                }
            }

            return false
        } else if (requirement.user_settings) {
            if (!userSettings) {
                return false
            }

            for (const [key, value] of Object.entries(
                requirement.user_settings
            )) {
                if (typeof value === 'object') {
                    const firstEntry = Object.entries(value)[0]
                    if (
                        !compareField(
                            userSettings[key],
                            firstEntry[1],
                            firstEntry[0]
                        )
                    ) {
                        return false
                    }
                } else {
                    if (value !== userSettings[key]) {
                        return false
                    }
                }
            }
        } else if (requirement.available_carriers) {
            const carriersLeft = carriersLimit - carriersCount

            if (carriersLeft === requirement.available_carriers) {
                return true
            }

            if (
                carriersLeft > requirement.available_carriers ||
                carriersLeft === 0
            ) {
                return false
            }

            return false
        } else if (requirement.available_origins) {
            const originsLeft = originsLimit - originsCount

            if (originsLeft === requirement.available_origins) {
                return true
            }

            if (
                originsLeft > requirement.available_origins ||
                originsLeft === 0
            ) {
                return false
            }

            return false
        } else if (requirement.available_quick_rules) {
            const quickRulesLeft = quickRulesLimit - quickRulesCount

            if (quickRulesLeft === requirement.available_quick_rules) {
                return true
            }

            if (
                quickRulesLeft > requirement.available_quick_rules ||
                quickRulesLeft === 0
            ) {
                return false
            }

            return false
        } else if (requirement.or) {
            let result = false
            for (const entry of requirement.or) {
                if (checkFieldRequirements([entry], options, values)) {
                    result = true
                    break
                }
            }

            if (!result) {
                return false
            }
        } else if (requirement.and) {
            for (const entry of requirement.and) {
                if (!checkFieldRequirements([entry], options, values)) {
                    return false
                }
            }
            return true
        } else if (requirement.not) {
            const result = checkFieldRequirements(
                requirement.not,
                options,
                values
            )

            if (result) {
                return false
            }
        } else if (requirement.non_default_value_for) {
            const result = checkFieldRequirements(
                requirement.non_default_value_for,
                options,
                values
            )

            if (result && isDefaultValue) {
                return false
            }

            return true
        } else if (requirement === 'non_default_value') {
            return !isDefaultValue
        } else if (requirement.visible) {
            if (
                !renderedFields ||
                renderedFields[requirement.visible] === undefined
            ) {
                return false
            }
        } else if (requirement.cross_border_provider) {
            return isCrossBorderProvider(
                requirement.cross_border_provider,
                crossBorderSettings
            )
        }
    }

    return result
}

const planMapping = {
    upsctpent: 'enterprise',
    upsctppro: 'pro',
    upsctp: 'starter',
    freeupsaccesspoint: 'free',
}

/**
 * Verify if currentPlan matches specified plan or is contained in the list of plans
 * @param currentPlan
 * @param planOrPlans
 * @returns {boolean}
 */
function isPlan(currentPlan, planOrPlans) {
    let planList = Array.isArray(planOrPlans) ? planOrPlans : [planOrPlans]

    const mappedPlan = planMapping[currentPlan]
    if (mappedPlan) {
        return planList.includes(mappedPlan)
    }

    return planList.includes(currentPlan)
}

/**
 * Verify if currentPlan matches specified plan or is contained in the list of plans
 * @param currentPlan
 * @param planOrPlans
 * @returns {boolean}
 */
const isUpsellPlan = ({ plan: currentPlan, platform, upsellPlans }) => {
    const availablePlans = AVAILABLE_PLATFORM_PLANS[platform] || []
    const availableUpsellPlans = availablePlans.filter((plan) =>
        upsellPlans.includes(plan)
    )

    return !availableUpsellPlans.includes(currentPlan)
}

export function isDefaultValue(value, defaultValue) {
    if (Array.isArray(value)) {
        if (
            !value.length &&
            (!defaultValue || defaultValue === '' || defaultValue === 0)
        ) {
            return true
        }
        return false
    }

    if (typeof defaultValue === 'string') {
        if (defaultValue.length && /[\d-]/.test(defaultValue)) {
            try {
                const intValue = parseInt(value)
                if (!intValue) {
                    return true
                } else {
                    return parseFloat(defaultValue) === parseFloat(value)
                }
            } catch (e) {
                return defaultValue === value
            }
        }
    }

    return defaultValue === value
}
