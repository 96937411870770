import React from 'react'
import styled from 'styled-components'

export const Title = styled.h2`
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 15px !important;
`

export const FormStepTitle = styled.h2`
    display: flex;
    gap: 4px;
    color: ${({ theme }) => theme.colors.dkblue1};
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSizes.medium};
`

export const DangerTypography = styled.span`
    color: ${({ theme }) => theme.colors.shqred3};
`

export const Description = styled.h5`
    text-align: center;
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 32px;

    &.transparent {
        background: transparent;
    }

    &.left {
        text-align: left;
    }

    &.small {
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 24px;
        font-weight: 400;

        label {
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 24px;
            font-weight: 400;
        }
    }
`

export const SubTitle = styled.h4`
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
`

interface StepLayoutProps {
    title?: string
    hint?: string
    subTitle?: string
}

const StepLayout: React.FC<StepLayoutProps> = ({
    children,
    title,
    hint,
    subTitle,
}) => {
    return (
        <div>
            {title && <Title>{title}</Title>}

            {hint && <Description>{hint}</Description>}

            {subTitle && <SubTitle>{subTitle}</SubTitle>}

            {children}
        </div>
    )
}

export default StepLayout
