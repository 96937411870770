import { axiosClient } from 'net/ajax'
import { useQuery } from 'react-query'

async function getFeaturesPlans() {
    return await axiosClient
        .get('/ratesmgr/features/plans.json', {})
        .then(({ data }) => data)
}

export default function useFeaturesPlans() {
    const queryResult = useQuery('features_plans', () => getFeaturesPlans())

    return {
        ...queryResult,
        featuresPlans: queryResult.data || {},
    }
}
