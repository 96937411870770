import { useDispatch, useSelector } from 'react-redux'
import { loadCarrierForm } from 'reducers/modules/integrations'
import { useEffect } from 'react'

export default function useLogos(preload = true) {
    const logos = useSelector(({ app }) => app.integrations.logos)
    const dispatch = useDispatch()

    // make sure we have logos on mount
    useEffect(() => {
        if (!logos && preload) {
            dispatch(loadCarrierForm())
        }
    }, [])

    return logos || {}
}
