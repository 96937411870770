import checkFieldRequirements from 'components/ui/form/fieldRequirements'

export function selectDropdownDefaultValue(forceSelect, options, formValues) {
    if (typeof forceSelect === 'object') {
        for (const [key, value] of Object.entries(forceSelect)) {
            if (checkFieldRequirements(value, {}, formValues)) {
                return key
            }
        }
    } else {
        return options[0].value
    }
}
