import { createContext } from 'react'

/* $FlowFixMe */
const MultiStepFormContext = createContext({
    values: {},
    resetForm: () => {},
    disableAutocomplete: false,
})

export default MultiStepFormContext
