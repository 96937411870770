//

function emptyFilter(entry) {
    return String(entry).length > 0
}

const isZero = (value) => Number.parseFloat(value) === 0

export const compareField = (value1, value2, op) => {
    if (!op) {
        if (
            value2 === 'true' ||
            value2 === 'false' ||
            typeof value2 === 'boolean'
        ) {
            return toBoolean(value1) === toBoolean(value2)
        }
        return value1 === value2
    }

    if (
        typeof value1 === 'string' &&
        typeof value2 === 'string' &&
        !isMathOp(op)
    ) {
        return compareStringField(value1, value2, op)
    }

    switch (op) {
        case 'has_less_than':
            if (!value1 || !Array.isArray(value1)) {
                return true
            }
            return value1.filter(emptyFilter).length < value2
        case 'has_more_than':
            if (!value1 || !Array.isArray(value1)) {
                return false
            }
            return value1.filter(emptyFilter).length > value2
    }

    if (Array.isArray(value1)) {
        switch (op) {
            case 'nin':
                return !value1.includes(value2)

            case 'is_empty':
                return !value1 || value1.filter(emptyFilter).length === 0

            default:
                return value1.includes(value2)
        }
    }

    if (Array.isArray(value2)) {
        switch (op) {
            case 'nin':
                return !value2.includes(value1)

            default:
                return value2.includes(value1)
        }
    }

    const a = Number.parseFloat(String(value1)),
        b = Number.parseFloat(value2)

    switch (op) {
        case 'lte':
            if (isNaN(a) || isNaN(b)) {
                return true
            }

            return a <= b

        case 'gte':
            if (isNaN(a) || isNaN(b)) {
                return true
            }

            return a >= b

        case 'lt_or_zero':
            if (isNaN(a) || isNaN(b)) return true
            if (isZero(a) && isZero(b)) return true

            return a < b

        case 'gt_or_zero':
            if (isNaN(a) || isNaN(b)) return true
            if (isZero(a) && isZero(b)) return true

            return a > b

        case 'lt':
            if (isNaN(a) || isNaN(b)) {
                return true
            }

            return a < b

        case 'gt':
            if (isNaN(a) || isNaN(b)) {
                return true
            }

            return a > b

        case 'is_empty':
            return !value1

        case 'is_not_empty':
            return !!value1

        case 'not_zero':
            return parseFloat(a) !== 0.0

        case 'not':
            if (isNaN(a) || isNaN(b)) {
                return true
            }

            return a !== b

        case 'neq':
            return value1 !== value2

        default:
            return value1 === value2
    }
}

function toBoolean(value) {
    if (typeof value === 'boolean') {
        return value
    } else {
        return value === 'true'
    }
}
const compareStringField = (a, b, op) => {
    switch (op) {
        case 'not':
            return a !== b

        case 'neq':
            return a !== b

        default:
            return a === b
    }
}

function isMathOp(op) {
    return [
        'lt',
        'gt',
        'lte',
        'gte',
        'not_zero',
        'gt_or_zero',
        'lt_or_zero',
    ].includes(op)
}
