import { useState, useEffect } from 'react'

export default function useRailsRoutes() {
    const [routes, setRoutes] = useState(window.Routes)

    // if routes ever change, make sure the
    // state updates
    useEffect(() => {
        setRoutes(window.Routes)
    }, [window.Routes])

    return routes
}
