export const isCrossBorderProvider = (
    crossBorderProvider,
    crossBorderSettings
) => {
    if (!crossBorderSettings) return false
    // console.log('crossBorderProvider', crossBorderProvider)
    // console.log('crossBorderSettings', crossBorderSettings)

    if (crossBorderProvider === 'dhl') {
        return crossBorderSettings.landing_cost_provider === 'dhl'
    } else if (crossBorderProvider === 'hurricane') {
        return ['hurricane', null, '', undefined].includes(
            crossBorderSettings.landing_cost_provider
        )
    }
}
