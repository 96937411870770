import React, {
    Component,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import cx from 'classnames'
import DropdownLabel from 'components/ui/DropdownLabel'
import keyboardKey from 'keyboard-key'
import DropdownItem from './DropdownItem'
import { fieldId } from 'components/ui/form/renderers'
import { useForm } from 'react-final-form'

function isOptionSelected(selected, value) {
    if (Array.isArray(selected)) {
        return selected.includes(String(value)) || selected.includes(value)
    }

    if (!selected && value === '') {
        return true
    }

    return selected === value
}

/**
 * Filters drop-down options list
 * @param searchValue
 * @param selected
 * @param options
 * @returns {*[]|Array<$NonMaybeType<OptionsDef>>|Array<OptionsDef>|[]|*}
 */
function filterOptions(searchValue, selected, options) {
    const loweredSearchV = searchValue ? searchValue.toLowerCase() : ''

    if (!options?.length) {
        return []
    }

    if (selected && !searchValue?.length && !selected.length) {
        return options
    }

    return options.filter((entry) => {
        if (selected) {
            if (Array.isArray(selected)) {
                if (
                    selected.find(
                        (value) =>
                            value === entry.value ||
                            String(value) === String(entry.value)
                    )
                ) {
                    return false
                }
            } else if (selected.includes(entry.value)) {
                return false
            }
        }

        if (entry.value === '' && entry.title === '') {
            return false
        }

        return searchValue && searchValue?.length > 0
            ? entry.title.toLowerCase().includes(loweredSearchV)
            : true
    })
}

/**
 * Filters only selected options (used for read-only dropdown)
 * @param searchValue
 * @param selected
 * @param options
 * @returns {OptionsDef[]|*[]|Array<$NonMaybeType<OptionsDef>>|Array<OptionsDef>|[]|*}
 */
function filterSelectedOptions(searchValue, selected, options) {
    const loweredSearchV = searchValue ? searchValue.toLowerCase() : ''

    if (!options?.length) {
        return []
    }

    if (!searchValue?.length && !selected.length) {
        return options
    }

    const selectedSet = new Set()
    for (const selection of selected) {
        selectedSet.add(String(selection))
    }

    const result = []
    for (const entry of options) {
        if (result.length >= 100) {
            return result
        }

        if (entry.value === '' && entry.title === '') {
            // eslint-disable-next-line no-continue
            continue
        }

        if (!selectedSet.has(String(entry.value))) {
            // eslint-disable-next-line no-continue
            continue
        }

        if (searchValue && searchValue.length > 0) {
            if (entry.title.toLowerCase().includes(loweredSearchV)) {
                result.push(entry)
            }
        } else {
            result.push(entry)
        }
    }

    return result
}

function DropdownWithSearchReadonly(props) {
    const {
        options,
        disabled,
        selected,
        emptyMessage,
        onChange,
        highlightValue,
        highlightValuePrefix,
        name,
        placeholder,
        id,
    } = props

    const form = useForm()
    const timeout = useRef(null)
    const [searchQuery, setSearchQuery] = useState('')
    const filteredOptions = useMemo(() => {
        if (!searchQuery) {
            return options
        }
        return filterSelectedOptions(searchQuery, selected, options)
    }, [options, searchQuery])

    /**
     * debounced setter
     * @param query
     */
    const scheduleSearch = (query) => {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }

        timeout.current = setTimeout(() => {
            setSearchQuery(query)
        }, 500)
    }

    /**
     * Don't send the value of the field with the POST data
     */
    useEffect(() => {
        form.change(`_skip_${name}`, true)
    }, [])

    return (
        <DropdownWithSearchLayout
            options={filteredOptions}
            disabled={disabled}
            allowCreate={false}
            selected={selected}
            emptyMessage={emptyMessage}
            onChange={onChange}
            highlightValue={highlightValue}
            highlightValuePrefix={highlightValuePrefix}
            onSearch={scheduleSearch}
            placeholder={placeholder}
            id={id}
            name={name}
        />
    )
}

function DropdownSearch(props) {
    const { disabled, onSearch, placeholder, id } = props

    const [searchValue, setSearchValue] = useState('')

    if (disabled) {
        return null
    }

    useEffect(() => {
        onSearch(searchValue)
    }, [searchValue])

    return (
        <li className="srch-field">
            <input
                id={id}
                type="text"
                aria-autocomplete="list"
                autoComplete="off"
                value={searchValue}
                data-testid="dws-search-query"
                placeholder={placeholder}
                onChange={(e) => {
                    setSearchValue(e.target.value)
                }}
            />
        </li>
    )
}

function DropdownWithSearchLayout(props) {
    const {
        disabled,
        options,
        selected,
        emptyMessage,
        highlightValue,
        highlightValuePrefix,
        onSearch,
        placeholder,
        id,
        name,
    } = props
    const wrapperRef = useRef(null)
    const [opened, setOpened] = useState(false)
    const divClass = cx(
        'show-tick optional multiselect-ignore searchable-select searchable-select-readonly',
        { open: opened },
        { disabled }
    )

    const onClickInside = () => {}

    // we need to cache this to prevent rerenders of the whole list
    const toggle = useCallback(() => {
        setOpened(!opened)
    }, [opened])

    const hasSelection = true

    return (
        <>
            <div className={divClass} ref={wrapperRef}>
                <div className="chosen-container chosen-container-multi chosen-with-drop chosen-container-active">
                    <ul
                        className="chosen-choices form-control select"
                        onMouseDown={onClickInside}
                    >
                        <i
                            id={fieldId(name)}
                            className={cx('far', {
                                'fa-angle-down': !opened,
                                'fa-times': opened,
                            })}
                            onMouseDown={toggle}
                            role="button"
                        />

                        <DropdownSearch
                            id={id}
                            onSearch={onSearch}
                            disabled={disabled}
                            placeholder={placeholder}
                        />

                        <OptionLabels
                            options={options}
                            select={null}
                            hasSelection={hasSelection}
                            opened={opened}
                            highlightValue={highlightValue}
                            highlightValuePrefix={highlightValuePrefix}
                            selected={selected}
                            emptyMessage={emptyMessage}
                            toggle={toggle}
                        />
                    </ul>
                </div>
            </div>
        </>
    )
}

const OptionLabels = React.memo((props) => {
    const {
        options,
        hasSelection,
        emptyMessage,
        opened,
        selected,
        highlightValue,
        highlightValuePrefix,
        select,
        toggle,
    } = props

    let optionsSlice = useMemo(
        () =>
            options
                .filter((item) => isOptionSelected(selected, item.value))
                .slice(0, 100),
        [options]
    )

    if (!options || !options.length || !hasSelection) {
        if (emptyMessage) {
            return <li className="srch-show-more">{emptyMessage}</li>
        }

        return null
    }

    const displayShowMore = opened ? false : optionsSlice.length > 20
    if (!(opened || optionsSlice.length <= 20)) {
        optionsSlice = optionsSlice.slice(0, 20)
    }

    return (
        <>
            {optionsSlice.map((item) => (
                <StyledOption
                    key={item.value}
                    option={item}
                    highlightValue={highlightValue}
                    highlightValuePrefix={highlightValuePrefix}
                    select={select}
                />
            ))}
            {displayShowMore && (
                <li className="srch-show-more">
                    <a
                        href="#"
                        onMouseDown={(e) => {
                            e.preventDefault()
                            toggle()
                            return false
                        }}
                    >
                        Show All...
                    </a>
                </li>
            )}
        </>
    )
})

class DropdownWithSearchEditable extends Component {
    wrapperRef

    searchInputRef

    static defaultProps = {
        disabled: false,
        onChange: () => {},
    }

    constructor(props) {
        super(props)

        this.wrapperRef = React.createRef()
        this.searchInputRef = React.createRef()

        this.state = {
            opened: false,
            selected: props.selected,
            searchValue: '',
            hovered: -1,
            options: this.filterOptions(''),
            showAll: false,
            searchFieldFocused: false,
        }

        this.navigate = this.navigate.bind(this)
        this.scrollSelectedItemIntoView =
            this.scrollSelectedItemIntoView.bind(this)
        this.onCreateNew = this.onCreateNew.bind(this)
        this.onClickInside = this.onClickInside.bind(this)
    }

    filterOptions(
        searchValue = this.state.searchValue,
        selected = this.props.selected
    ) {
        const { options } = this.props
        return filterOptions(searchValue, selected, options)
    }

    componentDidMount() {
        const { selected, forceSelect, options } = this.props
        if (!selected && forceSelect) {
            this.select(options[0].value)
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { selected } = props
        return {
            ...state,
            options: filterOptions(state.searchValue, selected, props.options),
            selected,
        }
    }

    callFocusOrBlur = () => {
        const { opened } = this.state
        const { onFocus, onBlur } = this.props
        if (opened && onFocus) {
            onFocus()
        } else if (onBlur) {
            onBlur()
        }
    }

    toggle = () => {
        const { disabled } = this.props

        if (disabled) {
            return false
        }

        const { opened } = this.state
        this.setState(
            {
                opened: !opened,
                searchValue: '',
                options: this.filterOptions(''),
            },
            this.callFocusOrBlur
        )

        return false
    }

    hide = () => {
        this.setState(
            {
                opened: false,
                searchValue: '',
                options: this.filterOptions(''),
            },
            this.callFocusOrBlur
        )
    }

    select = (value) => {
        const { multiSelect, highlightValue, onChange } = this.props

        if (
            highlightValue &&
            (highlightValue === value ||
                String(value) === String(highlightValue))
        ) {
            return
        }

        if (multiSelect) {
            const { selected } = this.state

            const result = selected
                ? Array.from(selected).filter((item) => item !== value)
                : []

            if (!selected || !selected.includes(value)) {
                result.push(value)
            }

            const newState = {
                selected: result,
                userInput: true,
                searchValue: '',
                options: this.filterOptions('', result),
            }

            if (!newState.options || !newState.options.length) {
                newState.searchFieldFocused = false
            }

            newState.opened = newState.options.length > 0

            this.setState(newState, this.callFocusOrBlur)

            onChange(result.length > 0 ? result : [''])
        } else {
            this.setState(
                {
                    selected: value,
                    opened: false,
                    userInput: true,
                },
                this.callFocusOrBlur
            )

            onChange(value)
        }
    }

    handleClickOutside = (event) => {
        if (
            this.wrapperRef &&
            /* $FlowFixMe */
            event.target.isConnected &&
            this.wrapperRef.current &&
            !this.wrapperRef.current.contains(event.target)
        ) {
            this.hide()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { opened } = this.state

        if (prevState.opened && !opened) {
            document.removeEventListener('mousedown', this.handleClickOutside)
        } else if (!prevState.opened && opened) {
            document.addEventListener('mousedown', this.handleClickOutside)
        }
    }

    getValueCmp(values) {
        const { multiSelect } = this.props

        if (multiSelect) {
            return (option) => values && values.includes(option.value)
        }

        const value = Array.isArray(values) ? values.join('') : values

        return (option) => {
            return value === option.value
        }
    }

    getTitleByValue = (values) => {
        const { multiSelect } = this.props
        const { options: optionsOriginal } = this.props

        if (!optionsOriginal) {
            return ''
        }

        const options = optionsOriginal
            ? optionsOriginal.filter(this.getValueCmp(values))
            : []

        if (options && options.length > 0) {
            return options.map((option) => option.title).join(', ')
        }

        const { askToSelect } = this.props

        if (askToSelect && askToSelect.length > 0) {
            return askToSelect
        }

        if (multiSelect) {
            return 'N/A'
        }

        return ''
    }

    renderLabels() {
        const { selected, showAll, opened } = this.state
        const { options, highlightValue, highlightValuePrefix } = this.props

        let hasSelection =
            selected &&
            selected.length &&
            (Array.isArray(selected)
                ? !!selected.filter((item) => item !== '').length
                : true)

        if (!options || !options.length || !hasSelection) {
            const { emptyMessage } = this.props
            if (emptyMessage) {
                return <li className="srch-show-more">{emptyMessage}</li>
            }

            return null
        }

        if (opened || selected.length <= 20 || showAll)
            return options
                .filter((item) => isOptionSelected(selected, item.value))
                .map((item) => (
                    <StyledOption
                        key={item.value}
                        option={item}
                        highlightValue={highlightValue}
                        highlightValuePrefix={highlightValuePrefix}
                        select={this.select}
                    />
                ))
        return (
            <>
                {options
                    .filter((item) => isOptionSelected(selected, item.value))
                    .slice(0, 20)
                    .map((item) => (
                        <StyledOption
                            key={item.value}
                            option={item}
                            highlightValue={highlightValue}
                            highlightValuePrefix={highlightValuePrefix}
                            select={this.select}
                        />
                    ))}
                <li className="srch-show-more">
                    <a
                        href="#"
                        onMouseDown={(e) => {
                            e.preventDefault()
                            this.setState({ showAll: true })
                            return false
                        }}
                    >
                        Show All...
                    </a>
                </li>
            </>
        )
    }

    navigate(e) {
        const { options, hovered, selected, searchValue } = this.state
        const keyCode = keyboardKey.getCode(e)

        if (keyCode === keyboardKey.Backspace) {
            if (!searchValue.length) {
                const { options: allOptions, highlightValue } = this.props

                const valuesSorted = allOptions
                    .map((item) => item.value)
                    .filter((item) => selected.includes(item))

                let idxToRemove = valuesSorted.length - 1
                // can be <0 when there is only highlighted value
                if (idxToRemove < 0) {
                    return
                }

                // skip over the highlighted value which user shouldn't be able to remove
                if (
                    highlightValue &&
                    String(valuesSorted[idxToRemove]) === String(highlightValue)
                ) {
                    if (!idxToRemove) {
                        return
                    }

                    idxToRemove--
                }

                /* $FlowFixMe */
                selected.splice(selected.indexOf(valuesSorted[idxToRemove]), 1)

                const newOptions = this.filterOptions(undefined, selected)
                const newState = {
                    selected,
                    options: newOptions,
                }

                if (newOptions.length > 0) {
                    /* $FlowFixMe */
                    newState.opened = true
                }

                this.setState(newState, this.callFocusOrBlur)
            }

            return
        }

        if (keyCode === keyboardKey.Enter) {
            e.preventDefault()

            if (hovered !== -1 && options[hovered]) {
                this.setState(
                    {
                        searchValue: '',
                    },
                    () => {
                        this.select(options[hovered].value)
                    }
                )
            }

            return
        }

        const moves = {
            [keyboardKey.ArrowUp]: -1,
            [keyboardKey.ArrowDown]: 1,
        }

        const move = moves[keyCode]
        if (move === undefined) {
            return
        }

        let nextIndex = hovered + move
        if (nextIndex < 0) {
            nextIndex = options.length - 1
        } else if (nextIndex > options.length - 1) {
            nextIndex = 0
        }

        this.setState({ hovered: nextIndex }, this.scrollSelectedItemIntoView)
    }

    renderSearch() {
        const { disabled, placeholder, id } = this.props
        const { searchValue } = this.state
        if (disabled) {
            return null
        }

        return (
            <li className="srch-field">
                <input
                    type="text"
                    aria-autocomplete="list"
                    autoComplete="off"
                    value={searchValue}
                    ref={this.searchInputRef}
                    placeholder={placeholder}
                    id={id}
                    data-testid={id}
                    onFocus={(e) =>
                        this.setState(
                            { opened: true, searchFieldFocused: true },
                            this.callFocusOrBlur
                        )
                    }
                    onBlur={(e) => {
                        this.handleClickOutside(e)
                        this.setState({ searchFieldFocused: false })
                    }}
                    onChange={(e) => {
                        const searchValue = e.target.value
                        this.setState(
                            {
                                searchValue,
                                hovered: -1,
                            },
                            () => {
                                options: this.filterOptions(searchValue)
                            }
                        )
                    }}
                    onKeyDown={this.navigate}
                />
            </li>
        )
    }

    isHovered(idx) {
        const { hovered } = this.state
        return hovered !== -1 ? idx === hovered : false
    }

    scrollSelectedItemIntoView() {
        const { current: ref } = this.wrapperRef
        if (!ref) {
            return
        }

        const menu = ref.querySelector('div.d-menu')
        if (!menu) return
        const menuDropdown = menu.querySelector('a.hover')
        if (!menuDropdown) return
        const item = menuDropdown.parentNode
        if (!item) return
        const chosenContainer = ref.querySelector('.chosen-container')
        if (!chosenContainer) return

        const itemTop = item.offsetTop - chosenContainer.clientHeight
        const isOutOfUpperView = itemTop < menu.scrollTop
        const isOutOfLowerView =
            itemTop + item.clientHeight > menu.scrollTop + menu.clientHeight

        if (isOutOfUpperView) {
            menu.scrollTop = itemTop
        } else if (isOutOfLowerView) {
            // eslint-disable-next-line no-mixed-operators
            menu.scrollTop = itemTop + item.clientHeight - menu.clientHeight
        }
    }

    onCreateNew() {
        const { onCreateNew } = this.props

        this.setState(
            {
                opened: false,
            },
            this.callFocusOrBlur
        )

        if (onCreateNew) {
            onCreateNew()
        }
    }

    onClickInside(e) {
        const { disabled } = this.props
        if (disabled) {
            return
        }

        const { opened } = this.state
        if (opened) {
            return
        }

        if (
            e.target &&
            /* $FlowFixMe */
            e.target.className &&
            e.target.className.indexOf('chosen-choices') === 0
        ) {
            this.setState(
                {
                    opened: true,
                },
                this.callFocusOrBlur
            )

            if (this.searchInputRef && this.searchInputRef.current) {
                setTimeout(() => {
                    const { current } = this.searchInputRef
                    current && current.focus()
                }, 1)
            }
        }
    }

    render() {
        const { allowCreate, disabled, name } = this.props
        const { opened, options, searchValue } = this.state
        const divClass = cx(
            'show-tick optional multiselect-ignore searchable-select',
            { open: opened },
            { disabled }
        )

        return (
            <div className={divClass} ref={this.wrapperRef}>
                <div className="chosen-container chosen-container-multi chosen-with-drop chosen-container-active">
                    <ul
                        id={`${name}-selected-multiselect`}
                        data-testid={`${name}-selected-multiselect`}
                        className={cx('chosen-choices form-control select', this.props.className, {
                            disabled,
                        })}
                        onMouseDown={this.onClickInside}
                    >
                        <i
                            id={fieldId('test')}
                            className={cx('far', {
                                'fa-angle-down': !opened,
                                'fa-times': opened,
                            })}
                            onMouseDown={this.toggle}
                            role="button"
                        />
                        {this.renderLabels()}
                        {options && !options.length && !searchValue
                            ? null
                            : this.renderSearch()}
                    </ul>
                </div>
                <div className="d-menu open">
                    <ul
                        id={`${name}-inner-selectpicker`}
                        className="inner selectpicker"
                        aria-multiselectable="true"
                        role="listbox"
                    >
                        {/* $FlowFixMe */}
                        {options?.map((option, idx) => (
                            <DropdownItem
                                key={option.value}
                                title={option.title}
                                value={option.value}
                                selected={false}
                                hovered={this.isHovered(idx)}
                                onSelect={this.select}
                            />
                        ))}
                        {!options?.length ? (
                            <li>
                                <span className="empty">No results found</span>
                            </li>
                        ) : null}
                        {allowCreate && (
                            <DropdownItem
                                key="add_new"
                                title="Add New"
                                linkClass="new"
                                onSelect={this.onCreateNew}
                            />
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}

const StyledOption = React.memo(
    ({ option, highlightValue, highlightValuePrefix, select }) => {
        let extraClass

        const { value } = option
        let { title } = option
        if (highlightValue && String(highlightValue) === String(value)) {
            title = highlightValuePrefix + title
            extraClass = 'default'
        }

        return (
            <DropdownLabel
                title={title}
                value={value}
                onSelect={select}
                extraClass={extraClass}
            />
        )
    }
)

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function DropdownWithSearch(props) {
    const { selected } = props

    // 500 is still quite high, but there are for example 252 countries in the dropdown in the Zone entity form
    // so just leaving some room here to prevent issues
    // 11.06.2021: increasing to 5K, since UI is usable with this many items and some customers are reaching 1k selected options
    if (Array.isArray(selected) && selected.length > 5000) {
        return <DropdownWithSearchReadonly {...props} />
    }

    return <DropdownWithSearchEditable {...props} />
}
