import React from 'react'
import useLogos from 'hooks/useLogos'
import { logoStyle } from 'components/dashboard/integrations/containers/Index/components/utils'
import classnames from 'classnames'
import useTooltip from 'hooks/useTooltip'
import { ShortCuts } from 'components/dashboard/shortcuts/components'

const CARRIERS_INFO = {
    usps: { title: 'USPS®', note: 'Online rates' },
    fedEx: { title: 'FedEx', note: 'List rates' },
    ups: { title: 'UPS®', note: 'Published daily rates' },
}

interface ShortcutGoalProps {
    title: string
    note: string
    component?: (props: any) => JSX.Element
    logo: React.ReactElement
    variant?: VARIANT
    size?: STEP
    success?: boolean
    warning?: boolean
    onClick?: React.MouseEventHandler
    checked?: boolean
    navigateble?: boolean
    disabled?: boolean
    className?: string
    infoTooltip?: string
    componentProps?: any
    isRequiredGoal?: boolean
}

type VARIANT = 'carrier'
type STEP = 'small' | 'medium'

const variantClassNames = {
    carrier: 'variantCarrier',
}

const sizeClassNames = {
    small: 'sizeSmall',
    medium: 'sizeMedium',
}

const DefaultComponent = (props) => <div {...props} />

export const ShortcutGoal: React.FC<ShortcutGoalProps> = ({
    component: Component,
    componentProps,
    title,
    note,
    logo,
    variant,
    size = 'medium',
    success = false,
    warning = false,
    checked = false,
    navigateble = false,
    disabled = false,
    infoTooltip,
    onClick,
    className,
    isRequiredGoal,
}) => {
    const ButtonComponent = Component || DefaultComponent

    return (
        <ShortCuts.GoalItem
            as={ButtonComponent}
            className={classnames(
                className,
                variantClassNames[variant],
                sizeClassNames[size],
                {
                    checked,
                    success,
                    warning,
                    navigateble,
                    disabled,
                    clickable: !!onClick,
                }
            )}
            {...componentProps}
            onClick={onClick}
        >
            <div className="flex">
                <ShortCuts.Icon>{logo}</ShortCuts.Icon>

                <div>
                    {isRequiredGoal && (
                        <ShortCuts.RequiredTitle>
                            Action required
                        </ShortCuts.RequiredTitle>
                    )}

                    <ShortCuts.Title>{title}</ShortCuts.Title>

                    <ShortCuts.Note>{note}</ShortCuts.Note>
                </div>
            </div>

            <GoalSubIcon
                success={success}
                warning={warning}
                navigateble={navigateble}
                infoTooltip={infoTooltip}
            />
        </ShortCuts.GoalItem>
    )
}

const GoalSubIcon = ({ success, warning, navigateble, infoTooltip }) => {
    const tooltip = useTooltip()

    if (success) {
        return (
            <ShortCuts.SubIcon>
                <i className="fas fa-check-circle" />
            </ShortCuts.SubIcon>
        )
    }

    if (warning) {
        return (
            <ShortCuts.SubIcon>
                <i className="fas fa-circle-exclamation" />
            </ShortCuts.SubIcon>
        )
    }

    if (infoTooltip) {
        return (
            <ShortCuts.SubIcon
                ref={tooltip.ref}
                data-place="top"
                data-tip={infoTooltip}
            >
                <i className="far fa-info-circle " {...tooltip.props} />
            </ShortCuts.SubIcon>
        )
    }

    if (navigateble) {
        return (
            <ShortCuts.SubIcon>
                <i className="fa fa-arrow-right" />
            </ShortCuts.SubIcon>
        )
    }

    return null
}

export const CarrierShortcutGoal = ({ carrierType, success = null }) => {
    const logos = useLogos()
    const logo = logos && logos[carrierType]

    return (
        <ShortcutGoal
            success={success}
            variant="carrier"
            title={CARRIERS_INFO[carrierType].title}
            note={CARRIERS_INFO[carrierType].note}
            logo={<div style={carrierLogoStyles(logos, carrierType)} />}
        />
    )
}

export const carrierLogoStyles = (logos, carrierType) => ({
    backgroundColor: 'white',
    ...logoStyle(logos, carrierType).style,
    backgroundImage: `url(${logos[carrierType]})`,
})
