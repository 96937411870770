export const filterOptions = (searchValue, options) => {
    const loweredSearchV = searchValue.toLowerCase()

    if (loweredSearchV && loweredSearchV.length > 0) {
        return options
            .filter((entry) => {
                if (entry.title.toLowerCase().includes(loweredSearchV)) {
                    return true
                }

                /* $FlowFixMe */
                if (entry.options) {
                    /* $FlowFixMe */
                    for (const option of entry.options) {
                        if (
                            option.title.toLowerCase().includes(loweredSearchV)
                        ) {
                            return true
                        }
                    }
                }
            })
            .map((group) => {
                // if group title matches - show all the options
                if (
                    /* $FlowFixMe */
                    !group.options ||
                    group.title.toLowerCase().includes(loweredSearchV)
                ) {
                    return group
                }

                /* $FlowFixMe */
                const options = group.options.filter((option) =>
                    option.title.toLowerCase().includes(loweredSearchV)
                )

                return {
                    ...group,
                    options,
                }
            })
    } else {
        return options
    }
}
